import React from "react";
import {Button} from "semantic-ui-react";
import { isMobile} from "react-device-detect";
const config = require('../config');
const hostBackend = config.HOSTBackend;
const portBackend = config.PORTBackend;
const protocolBackend = config.ProtocolBackend;
const prefixBackend = config.PrefixBackend;
var urlBackend=protocolBackend+'://'+hostBackend+':'+portBackend+prefixBackend;
if (portBackend === "" || portBackend === undefined){
    urlBackend=protocolBackend+'://'+hostBackend+prefixBackend;
}
else{
    urlBackend=protocolBackend+'://'+hostBackend+':'+portBackend+prefixBackend;
}
const staticURL ='https://project9438467.tilda.ws/';
class WelcomeMessage extends React.Component {
    render() {
        let display = this.props.display
        let width= "76%";
        let left="12%";
        if ( isMobile){
            width="100%"
            left="0%"
        }
        if (this.props.match !== undefined)
            display = this.props.match.params.display;
        /**/
        return(
            <div className="welcome" style={{display:display,width:width,left:left}} >
                    <span>
                        <Button basic size={'mini'} onClick={this.props.closepopup} icon><i className="close icon"/></Button>
                        <br/>
                        {(this.props.display==="block") && (
                            <div className="second-row">
                                <iframe title="Landing" src={staticURL}/>
                            </div>
                        )}
                </span>
            </div>
        )}}
export default WelcomeMessage;

import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";

export default function LoadLayerOnStart(props) {
    const [loading,setLoading] = useState(false)
    let params = useParams();
    console.log(params)
    useEffect(() => {
        if (loading)
            return
        setLoading(true)
        if (params.layer !== undefined){
            console.log(params.layer)
            if (!props.routerInfosTrigger){
                //props.setRouterInfosTrigger(true);
                let layer = params.layer;
                let tempResult = JSON.parse(JSON.stringify(props.result))
                for ( let i = 0 ; i < props.result.length;i++){
                    if(props.result[i].name !==undefined)
                        if (props.result[i].name.toLowerCase() === layer) {
                            if (tempResult[i].visibility !== 'visible') {
                                tempResult[i].visibility = 'visible'
                            }
                        }
                }
                let tempData2 = JSON.parse(JSON.stringify(props.data2))
                for (let j = 0; j < tempData2.length; j++) {
                    if (tempData2[j].name.toLowerCase() === layer.toLowerCase()+"text") {
                        tempData2[j].enable = true;
                    }
                }
                //props.setResult(tempResult)
                //props.setData2(tempData2)
                props.update("layer",layer)
                console.log(tempResult)
            }
        }
    }, [loading, params.layer, props]);
    


}
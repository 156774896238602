import React, { useState, useEffect,useMemo } from 'react';
import {styled, alpha,ThemeProvider, createTheme } from '@mui/material/styles';
//material-ui
import "../Styles/appMenu.css"
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import {BrowserView,MobileView} from "react-device-detect";
import LayersIcon from '@mui/icons-material/Layers';
import SearchInput from './appMenuSearchInput'
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import MapIcon from '@mui/icons-material/Map';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import ToolIcon from '@mui/icons-material/Build'
import BurstModeIcon from '@mui/icons-material/BurstMode';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import ImageIcon from '@mui/icons-material/Image';
import HelpIcon from '@mui/icons-material/Help';
import ProfileIcon from '@mui/icons-material/Contacts';
import ExitIcon from '@mui/icons-material/ExitToApp';
import RegisterIcon from '@mui/icons-material/HowToReg';
import LoginIcon from '@mui/icons-material/AccountBox';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import QueryStatsIcon from '@mui/icons-material/Comment';
import StreetviewIcon from '@mui/icons-material/Streetview';
import Cookies from 'js-cookie'
import i18next from '../i18n';
import { useTranslation } from 'react-i18next';
import MenuList from "@mui/material/MenuList";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Assessment from "@mui/icons-material/Assessment";
import {Box} from "@mui/material";
var config = require('../config');
const regURL = config.regURL;
const help_url = config.helpURL;
var banner = require('../Interface/banner.png');
var layerDisplay = 'none';
var loginDisplay = 'none';
var registerDisplay = 'none';
var searchDisplay = 'none';
var filterDisplay = 'none';
var imageDisplay = 'none';
var kernDisplay = 'none';
var aboutDisplay = 'none';
var wikiDisplay = 'none';
var helpDisplay = 'none';
var auth2 = false;
const buttonText = {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Roboto',
    lineHeight: '30%',
    fontSize: '11px',
}
const theme = createTheme();
const TypographyWripper = styled(Typography)(({theme})=> ({
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        }
}));
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
        width: '20ch',
    },
}));
const SearchMobile = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchMobileIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(2.5, 2),
    height: '80%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBaseMobile = styled(InputBase)(({ theme }) => ({
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(0.5)}px)`,
    transition: theme.transitions.create('width'),
    width: '60%',
    [theme.breakpoints.up('md')]: {
        width: '18ch',
    },
}));
/*const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },

    //menuButton: {
    //    marginRight: theme.spacing(2),
    //},
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    menuItem: {
        "&:hover": {
            backgroundColor: "#3F51B5  !important",
            color: "white !important",
            "& .MuiListItemIcon-root": {
                color: "white !important"
            }
        }
    },
    icon:{
        "&:hover": {
            color:'white !important',
        }
    },
    iconButtonLabel: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Roboto',
        lineHeight: '30%',
        fontSize: '11px',
    },

    titlt2: {
        display:'inline',
        [theme.breakpoints.up('sm')]: {
            display: 'inline',
        },
    },
    titleLabel: {
        flexDirection: 'column'
    },
    toolbarButtons: {
        marginLeft: 'auto'
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    mobileSearch: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    mobileSearchIcon: {
        padding: theme.spacing(2.5, 2),
        height: '80%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    inputRootMobile: {
        //backgroundColor: "white",
        //border: "solid",
        width:"50%",
        left: 0
    },
    mobileInput:{
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(0.5)}px)`,
        transition: theme.transitions.create('width'),
        width: '60%',
        [theme.breakpoints.up('md')]: {
            width: '18ch',
        },
    },
}));

 */
export function LayerDisplay () {
   return layerDisplay;
}
export function ImageDisplay () {
    return imageDisplay;
}
export function LoginDisplay (login) {
    if (login)
        loginDisplay = login;
    return loginDisplay;
}
export function getAuth () {
    return auth2;
}
export function RegisterDisplay (login) {
    if (login)
        registerDisplay = login;
    return registerDisplay;
}
export function SearchDisplay () {
    return searchDisplay;
}
export function FilterDisplay () {
    return filterDisplay;
}
export function ShapeDisplay () {
    imageDisplay = "none";
    return imageDisplay;
}
export function KernDisplay () {

    //kernDisplay = "none";
    return kernDisplay;
}
export function WikiDisplay () {

    //kernDisplay = "none";
    return wikiDisplay;
}
export function HelpDisplay (login) {
    if (login)
        helpDisplay = login;
    return helpDisplay;
}
export function AboutDisplay (login) {
    if (login)
        aboutDisplay = login;
    return aboutDisplay;
}
export default function MenuAppBar(props) {

    auth2 = props.auth;
    const [kernPerm,setKernPerm] = React.useState(false);
    const [auth, setAuth] = React.useState(true);
    const [username, setUsername] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [toolAnchorEl, setToolAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const toolopen = Boolean(toolAnchorEl);
    const [searchD,setSearchD]=React.useState(null);
    const [registerD,setRegisterD]=React.useState(null);
    const [loginD,setLoginD]=React.useState(null);
    const [layerD,setLayerD]=React.useState(null);
    const [filterD,setFilterD]=React.useState(null);
    const [imageD,setImageD] = React.useState(null);
    const [kernD,setKernD] = React.useState(null);
    const [abloutD,setAboutD] =React.useState(null);
    const [wikiD,setWikiD] =React.useState(null);
    const [helpD,setHelpD] =React.useState(null);
    const { t, i18n } = useTranslation();
    useEffect(() => {
       //console.log(props.auth)
        if (props.auth === true) {
            auth2 = true;
            setAuth(true);

            if (Cookies.get("groups")!== undefined){
                let groups =Cookies.get("groups").split(',');
                setKernPerm(false)
                if (groups !== undefined)
                    for (let i = 0; i < groups.length; i++) {
                        if (groups[i] === 'Группа пользователей ПАО Лукойл' || Cookies.get("id") === "1")
                            setKernPerm(true)
                    }
            }

        }else
            setKernPerm(false)
    });

    //console.log(props.auth,auth,auth2)
    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
        if (Cookies.get("id") !== undefined) {
            auth2 = true;
            setUsername(Cookies.get("username"));
            setAuth(true);
        }
        else if(Cookies.get("id") === undefined){
            auth2 = false;
            setAuth(false);
        }
    };
    const handleToolMenu = event => {
        console.log(event)
        setToolAnchorEl(event.currentTarget);
    };
    const handleLogout = () => {
        //console.log("close",auth);
        auth2 = false;
        Cookies.remove("id");
        Cookies.remove("username");
        Cookies.remove('token')
        Cookies.remove('session')
        Cookies.remove("groups");
        props.authhandle();
        setAuth(false);
    };
    const handleClose = () => {
        setToolAnchorEl(null);
        setAnchorEl(null);
    };
    const handleToolClose = () => {
        setToolAnchorEl(null);
        setAnchorEl(null);
    };
    const handleLogin = () => {
        setToolAnchorEl(null);
        setAnchorEl(null);
        if (loginDisplay === 'block')
            loginDisplay = 'none';
        else
            loginDisplay = 'block';
        setLoginD(loginDisplay);
    };
    const handleLayers = () => {
        setToolAnchorEl(null);
        setAnchorEl(null);
        if (layerDisplay === 'block')
            layerDisplay = 'none';
        else
            layerDisplay = 'block';
        setLayerD(layerDisplay)
    };
    const handleImage = () => {
        setToolAnchorEl(null);
        setAnchorEl(null);
        if (imageDisplay === 'block')
            imageDisplay = 'none';
        else
            imageDisplay = 'block';
        setImageD(imageDisplay)
    };
    const handleFilter = () => {
        setToolAnchorEl(null);
        setAnchorEl(null);
        if (filterDisplay === 'block')
            filterDisplay = 'none';
        else
            filterDisplay = 'block';
        setFilterD(filterDisplay)
    };
    const handleSearch = () => {
        localStorage.clear();
        window.location.reload();
        setToolAnchorEl(null);
        setAnchorEl(null);
        if (searchDisplay === 'inline-block')
            searchDisplay = 'none';
        else
            searchDisplay = 'inline-block';
        setSearchD(searchDisplay)
    };
    const handleAbout = () => {
        setToolAnchorEl(null);
        setAnchorEl(null);
        if (aboutDisplay === 'block')
            aboutDisplay = 'none';
        else
            aboutDisplay = 'block';
        setAboutD(aboutDisplay)
    };
    const handleKern = () => {
        setToolAnchorEl(null);
        setAnchorEl(null);
        if (kernDisplay === 'block')
            kernDisplay = 'none';
        else
            kernDisplay = 'block';
        setKernD(kernDisplay)
    };
    const handleWiki = () => {
        setToolAnchorEl(null);
        setAnchorEl(null);
        if (wikiDisplay === 'block')
            wikiDisplay = 'none';
        else
            wikiDisplay = 'block';
        setWikiD(wikiDisplay)
    };
    const handleHelp = () => {
        setToolAnchorEl(null);
        setAnchorEl(null);
        if (helpDisplay === 'block')
            helpDisplay = 'none';
        else
            helpDisplay = 'block';
        setHelpD(helpDisplay)
    };
    return (
        <Box
            sx={{ flexGrow: 1 }}
            //className={classes.grow}
        >
            <ThemeProvider theme={theme}>
            <AppBar sx={{ bgcolor: "#3F51B5" }} style={{  color: "#fff!important"}} position="static">
                <Toolbar>
                        <IconButton

                            sx={{
                                "label":{display: 'flex',
                                    flexDirection: 'column',
                                    fontFamily: 'Roboto',
                                    lineHeight: '30%',
                                    fontSize: '11px',}
                            }}
                            style={buttonText}
                            aria-label="Control-panel"
                            //aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleLayers}
                            onClickCapture={props.layershandle}
                            color="inherit"
                        >
                            <LayersIcon/>
                            <br/>
                            {i18next.t('Layers')}
                        </IconButton>
                    <TypographyWripper>
                        <Typography style={{cursor: "pointer",marginTop: "15px"}} onClick={()=>{console.log(window);window.location.href=window.location.origin}}
                                    variant="h6" noWrap>
                            &emsp;<img src={banner}/>
                        </Typography>
                    </TypographyWripper>
                        <MobileView>
                            <SearchMobile id={"search"}
                            >
                                <SearchInput
                                   // classes={classes}
                                    sx={{flexGrow: 1}} mobile={true} searchblurhandle={props.searchblurhandle} searchinputhandle={props.searchinputhandle} searchfocushandle={props.searchfocushandle}/>
                                <IconButton
                                    id={"search-icon-button-map"}
                                    aria-label="Cancel search"
                                    //aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClickCapture={()=>{let a = document.getElementById("search-input");console.log("mobile click");props.mobileOsmSearch(a.value,true)}}
                                    //onClick={handleSearch}
                                    color="inherit"
                                    //transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    //style={{justifyContent: 'right',float: 'right'}}
                                >
                                    <MapIcon/>
                                </IconButton>
                            <IconButton
                                id={"search-icon-button-cancel"}
                                aria-label="Cancel search"
                                //aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClickCapture={props.searchblur2handle}
                                //onClick={handleSearch}
                                color="inherit"
                                //transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                //style={{justifyContent: 'right',float: 'right'}}
                            >
                                <CancelIcon/>
                            </IconButton>

                            <IconButton
                                id={"search-icon-button-search"}
                                aria-label="Control-panel"
                                //aria-controls="menu-appbar"
                                aria-haspopup="true"
                                //onClick={handleSearch}
                                onClickCapture={props.searchclickhandle}
                                color="inherit"
                            >
                                <ImageSearchIcon/>
                            </IconButton>
                        </SearchMobile>
                    </MobileView>
                    <BrowserView>
                        <Search id={"search"}

                        >
                            <SearchIconWrapper id={"search-icon"}

                            >
                                <SearchIcon onClick={()=>{let a = document.getElementById("search-input");console.log(" click");props.mobileOsmSearch(a.value,true)}} />
                            </SearchIconWrapper>
                            <SearchInput
                                //classes={classes}
                                sx={{flexGrow: 1}}  mobile={false} searchblurhandle={props.searchblurhandle} searchinputhandle={props.searchinputhandle} searchfocushandle={props.searchfocushandle}/>
                            <IconButton
                                id={"search-icon-button-cancel"}
                                aria-label="Cancel search"
                                //aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClickCapture={props.searchblur2handle}
                                //onClick={handleSearch}
                                color="inherit"
                                //transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                //style={{justifyContent: 'right',float: 'right'}}
                            >
                                <CancelIcon/>
                            </IconButton>

                            <IconButton
                                id={"search-icon-button-search"}
                                aria-label="Control-panel"
                                //aria-controls="menu-appbar"
                                aria-haspopup="true"
                                //onClick={handleSearch}
                                onClickCapture={props.searchclickhandle}
                                color="inherit"
                            >
                                <ImageSearchIcon/>
                            </IconButton>
                        </Search>
                    </BrowserView>
                    <BrowserView>
                    <IconButton
                        aria-label="Load image"
                        style={buttonText}
                        sx={{
                            label:{display: 'flex',
                                flexDirection: 'column',
                                fontFamily: 'Roboto',
                                lineHeight: '30%',
                                fontSize: '11px',}
                        }}
                        //aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleImage}
                        onClickCapture={props.imageclickhandle}
                        color="inherit"
                    >
                        <ImageIcon/>
                        {/* <Typography className={classes.title} variant="subtitle2" noWrap> */}
                        <br/>
                        {i18next.t('Upload2')}
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        {i18next.t('my layer')}
                        {/* </Typography> */}
                    </IconButton>

                    </BrowserView>
                    <BrowserView>
                        <IconButton
                            aria-label="Help"
                            style={buttonText}
                            sx={{
                                label:{display: 'flex',
                                    flexDirection: 'column',
                                    fontFamily: 'Roboto',
                                    lineHeight: '30%',
                                    fontSize: '11px',}
                            }}
                            //aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={()=> window.open(help_url, "_blank")}
                            //http://babilim.404.pc.imec.msu.ru:4450/
                            //onClick={handleHelp}
                            //onClickCapture={props.helpclickhandle}
                            color="inherit"
                        >
                            <HelpIcon/>
                            <br/>
                            {i18next.t('Help')}
                        </IconButton>
                    </BrowserView>

                    <BrowserView>
                        <IconButton
                            aria-label="Tool"
                            style={buttonText}
                            sx={{
                                label:{display: 'flex',
                                    flexDirection: 'column',
                                    fontFamily: 'Roboto',
                                    lineHeight: '30%',
                                    fontSize: '11px',}
                            }}
                            //aria-controls="menu-appbar"
                            aria-haspopup="true"
                            //onClick={handleHelp}
                            onClick={handleToolMenu}
                            color="inherit"
                        >
                            <ToolIcon/>
                            <br/>
                            {i18next.t('Tools')}
                        </IconButton>
                    </BrowserView>
                        <div
                            style={{
                                marginLeft: 'auto',
                                top: "10px",
                                position: "absolute",
                                right: "20px"
                            }}
                        >
                            <BrowserView>
                            <IconButton
                                aria-label="Reset settings"
                                sx={{
                                    label:{display: 'flex',
                                        flexDirection: 'column',
                                        fontFamily: 'Roboto',
                                        lineHeight: '30%',
                                        fontSize: '11px',}
                                }}
                                //aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClickCapture={props.searchhandle}
                                onClick={handleSearch}
                                color="inherit"
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                style={{...buttonText,justifyContent: 'right',float: 'right'}}
                            >
                                <CancelIcon/>

                                <br/>
                                {i18next.t('Reset options')}
                            </IconButton>
                            <IconButton
                                aria-label="account of current user"
                                //aria-controls="menu-appbar"
                                sx={{
                                    label:{display: 'flex',
                                        flexDirection: 'column',
                                        fontFamily: 'Roboto',
                                        lineHeight: '30%',
                                        fontSize: '11px',}
                                }}
                                //aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                style={{...buttonText,justifyContent: 'right',float: 'right'}}
                            >
                                <AccountCircle/>
                                {(Cookies.get('username')!==undefined) && (
                                    <div><br/>{Cookies.get('username')}</div>
                                    )}
                                {(Cookies.get('username')===undefined) && (
                                    <div><br/>{i18next.t('Login/Register')}</div>
                                )}
                            </IconButton>
                            <IconButton

                                sx={{
                                    label:{display: 'flex',
                                        flexDirection: 'column',
                                        fontFamily: 'Roboto',
                                        lineHeight: '30%',
                                        fontSize: '11px',}
                                }}
                                aria-label="Reset settings"
                                //aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClickCapture={props.abouthandle}
                                onClick={handleAbout}
                                color="inherit"
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                style={{...buttonText,justifyContent: 'right',float: 'right'}}
                            >
                                <InfoIcon/>
                                <br/>
                                {i18next.t('About project')}
                            </IconButton>
                                <span style={{top: "20px",
                                    position: "relative"}}>
                                {props.langArray.map((lang,index)=>{
                                    console.log(lang,index)
                                    let color = "#FFFFFF"
                                    if (lang === props.lang)
                                        color = "#01e01f"
                                    if ( index === 0){
                                        return(
                                            <span onClick={()=>{
                                                props.languagehandle(lang)
                                            }} style={{color: color}}>
                                              {lang}
                                            </span>
                                        )
                                    }
                                    else{
                                        return(
                                            <span>/
                                                <span onClick={()=>{
                                                    props.languagehandle(lang)
                                                }}  style={{color: color}}>
                                                    {lang}
                                                </span>
                                            </span>
                                        )
                                    }
                                })}
                            </span>
                            </BrowserView>
                            <Menu
                                //id="menu-appbar"
                                anchorEl={anchorEl}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                                keepMounted
                                open={open}
                                onClose={handleClose}
                            >
                                {!auth && (
                                    <div>
                                        <MenuItem sx={{
                                            "&:hover": {
                                                backgroundColor: "#3F51B5  !important",
                                                color: "white !important",
                                                "& .MuiListItemIcon-root": {
                                                    color: "white !important"
                                                }
                                            }
                                        }}  onClick={handleLogin} onClickCapture={props.loginhandle}>
                                            <ListItemIcon>
                                                <LoginIcon sx={{ "&:hover": { color: "white !important" } }} fontSize="small" />
                                            </ListItemIcon>

                                            <ListItemText>{i18next.t('Login')}</ListItemText>
                                        </MenuItem>
                                        <MenuItem sx={{
                                            "&:hover": {
                                                backgroundColor: "#3F51B5  !important",
                                                color: "white !important",
                                                "& .MuiListItemIcon-root": {
                                                    color: "white !important"
                                                }
                                            }
                                        }} onClick={()=> window.open(regURL, "_blank")} onClickCapture={props.registerhandle}>
                                            <ListItemIcon>
                                                <RegisterIcon sx={{ "&:hover": { color: "white !important" } }} fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText>{i18next.t('Register')}</ListItemText>

                                        </MenuItem>
                                    </div> )}
                                {auth && (
                                    <div>
                                        <MenuItem
                                            sx={{
                                                "&:hover": {
                                                    backgroundColor: "#3F51B5  !important",
                                                    color: "white !important",
                                                    "& .MuiListItemIcon-root": {
                                                        color: "white !important"
                                                    }
                                                }
                                            }}
                                            onClick={handleClose} onClickCapture={props.profilehandle} >
                                            <ListItemIcon>
                                                <ProfileIcon sx={{ "&:hover": { color: "white !important" } }} fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText>{i18next.t('Profile')}</ListItemText>
                                        </MenuItem>
                                        <MenuItem sx={{
                                            "&:hover": {
                                                backgroundColor: "#3F51B5  !important",
                                                color: "white !important",
                                                "& .MuiListItemIcon-root": {
                                                    color: "white !important"
                                                }
                                            }
                                        }} onClick={handleLogout} onClickCapture={props.authhandle}><ListItemIcon>
                                            <ExitIcon sx={{ "&:hover": { color: "white !important" } }} fontSize="small" />
                                        </ListItemIcon>
                                            <ListItemText>{i18next.t('Logout')}</ListItemText>

                                        </MenuItem>
                                    </div> )}
                            </Menu>
                            <Menu
                                //id="menu-appbar"
                                anchorEl={toolAnchorEl}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                                keepMounted
                                open={toolopen}
                                onClose={handleToolClose}
                            >
                                    <MenuList>
                                        {(kernPerm)&&(
                                            <MenuItem onClick={handleKern}
                                                      onClickCapture={props.kernclickhandle} sx={{
                                                "&:hover": {
                                                    backgroundColor: "#3F51B5  !important",
                                                    color: "white !important",
                                                    "& .MuiListItemIcon-root": {
                                                        color: "white !important"
                                                    }
                                                }
                                            }} >
                                                <ListItemIcon>
                                                    <MultilineChartIcon sx={{ "&:hover": { color: "white !important" } }} fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText>{i18next.t('Kern')}</ListItemText>
                                            </MenuItem>
                                        )}
                                        <MenuItem onClick={props.georefhandle} onClickCapture={handleToolClose} sx={{
                                            "&:hover": {
                                                backgroundColor: "#3F51B5  !important",
                                                color: "white !important",
                                                "& .MuiListItemIcon-root": {
                                                    color: "white !important"
                                                }
                                            }
                                        }} >
                                            <ListItemIcon>
                                                <BurstModeIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText>{i18next.t('Georeferencing')}</ListItemText>
                                        </MenuItem>
                                        <MenuItem onClick={props.lineshandle} onClickCapture={handleToolClose} sx={{
                                            "&:hover": {
                                                backgroundColor: "#3F51B5  !important",
                                                color: "white !important",
                                                "& .MuiListItemIcon-root": {
                                                    color: "white !important"
                                                }
                                            }
                                        }} >
                                            <ListItemIcon>
                                                <BurstModeIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText>{i18next.t('Lines')}</ListItemText>
                                        </MenuItem>
                                        <MenuItem onClickCapture={props.analogsclickhandle} onClick={handleToolClose}  sx={{
                                            "&:hover": {
                                                backgroundColor: "#3F51B5  !important",
                                                color: "white !important",
                                                "& .MuiListItemIcon-root": {
                                                    color: "white !important"
                                                }
                                            }
                                        }}>
                                            <ListItemIcon>
                                                <QueryStatsIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText>{i18next.t('Поиск Аналогов')}</ListItemText>
                                        </MenuItem>
                                        <MenuItem onClickCapture={props.model3dsclickhandle} onClick={handleToolClose}  sx={{
                                            "&:hover": {
                                                backgroundColor: "#3F51B5  !important",
                                                color: "white !important",
                                                "& .MuiListItemIcon-root": {
                                                    color: "white !important"
                                                }
                                            }
                                        }}>
                                            <ListItemIcon>
                                                <QueryStatsIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText>{i18next.t('Гео Модель')}</ListItemText>
                                        </MenuItem>
                                        <MenuItem onClickCapture={props.libraryhandle} onClick={handleToolClose}  sx={{
                                            "&:hover": {
                                                backgroundColor: "#3F51B5  !important",
                                                color: "white !important",
                                                "& .MuiListItemIcon-root": {
                                                    color: "white !important"
                                                }
                                            }
                                        }}>
                                            <ListItemIcon>
                                                <QueryStatsIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText>{i18next.t('Библиотека')}</ListItemText>
                                        </MenuItem>

                                        <MenuItem onClickCapture={props.reservesclickhandle} onClick={handleToolClose}  sx={{
                                            "&:hover": {
                                                backgroundColor: "#3F51B5  !important",
                                                color: "white !important",
                                                "& .MuiListItemIcon-root": {
                                                    color: "white !important"
                                                }
                                            }
                                        }}>
                                            <ListItemIcon>
                                                <QueryStatsIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText>{i18next.t('Reserves')}{' '}{i18next.t('calculation')}</ListItemText>
                                        </MenuItem>
                                        <MenuItem onClick={props.lasClickHandle} onClickCapture={handleToolClose}  sx={{
                                            "&:hover": {
                                                backgroundColor: "#3F51B5  !important",
                                                color: "white !important",
                                                "& .MuiListItemIcon-root": {
                                                    color: "white !important"
                                                }
                                            }
                                        }}>
                                            <ListItemIcon>
                                                <Assessment fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText>{i18next.t('Las Files')}</ListItemText>
                                        </MenuItem>
                                    </MenuList>
                            </Menu>
                        </div>
                </Toolbar>
            </AppBar>
            </ThemeProvider>
        </Box>

    );
}

import React, {Component} from "react";
import {Button, Checkbox, Icon, Input, Label, Popup} from "semantic-ui-react";
import i18n from "../i18n";
import FilterCheckBoxGroup from "./FilterCheckBoxGroup";
import FilterRangeSlider from "./FilterRangeSlider";
import Plot from "react-plotly.js";
import {urlBackend} from "../VariableInitialize";
import DatePicker from "react-datepicker";
import CheckboxTree from "react-checkbox-tree";
import Chronostrat from "./Chronostrat";
import GeometryFilter from "./GeometryFilter";
import ReservesFilter from "./ReservesFilter";
import AgeFilter from "./AgeFilter"
import {withTranslation} from "react-i18next";
import axios from "axios";
import Cookies from "js-cookie";
import InteractionFilter from "./InteractionFilter";
const GeometryFilterTranslate = withTranslation()(GeometryFilter)
const ReservesFilterTranslate = withTranslation()(ReservesFilter)
const AgeFilterTranslate = withTranslation()(AgeFilter)


class LayerFilters extends Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
            checked: [],
            expanded: [],
            checkedtest:{"Возраст":[],"Классификация":[],"Тип полезных ископаемых":[],"Статус лицензии":["Действующая","Аукцион","Планируемый аукцион","Инвестиционные возможности"],"Тип лицензии":[],"Тип":[],"Местность":[],"Расположение":[],"ObjectType":[],"Outcrop":[],"Метод открытия":[],"Степень Уверенности":[],"ProtectedAreasType":[],"ProtectedAreasProfile":[],'domain':[],'seismic_type':[],'signal_source':[],"Category":[],"Subcategory":[]},
            datetest:{"Дата выдачи лицензии":{start:new Date(),end:new Date()},"Дата окончания лицензи":{start:new Date(),end:new Date()}},
            value: 0,
            value2:"" ,
            value3:"",
            mapMenuDisplay:"none",
            mapMenuName:null,
            mapMenuPosition:{x:0,y:0},
            valueLength:0,
            word:null,
            reserves_params:[],
            reserves_categories:[],
            reserves_dicts:[],
            reserves_dicts_names:[],
            period:this.props.age_period,
            id:this.props.value,
            textselect :[],
            currentType:null,
            currentFilterName:"",
            textDisplay:"none",
            numberDisplay:"none",
            wordDisplay:"none",
            infoDisplay:'none',
            typing: false,
            typingTimeout: 0,
            typing2: false,
            typingTimeout2: 0,
            typing3:false,
            typingTimeout3: 0,
            active:false,
            title:"And",
            sliderValue:[1800, new Date().getFullYear()],
        };
        this.handleString = this.handleString.bind(this);
        this.handleResetAll = this.handleResetAll.bind(this);
        this.handleSetNullCheckBox = this.handleSetNullCheckBox.bind(this);
        // FilterLayerData={FilterLayerData} Filters={Filters} minGradient={minGradient} maxGradient={maxGradient} histogramValue={histogramValue} maxWinValue={maxWinValue} histogramValue2={histogramValue2} maxAuctionValue={maxAuctionValue} age_period={age_period} age_word={age_word}
        for (let i = 0; i < this.props.FilterLayerData.length; i++) {
            if (this.props.FilterLayerData[i].name.toLowerCase() === this.state.id.toLowerCase()) {
                //console.log(FilterLayerData[i].list,this.state.id);
                this.setState({textselect: this.props.FilterLayerData[i].list,value:i});
            }
        }
        for(let i = 0 ; i<this.props.Filters.length;i++){
            if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()){
                if (this.props.Filters[i].switch === true){
                    this.setState({active:true,title:"Or"});
                }
                else
                    this.setState({active:false,title:"And"});
            }
        }
    }
    handleSlider = (value,j,name) => {
        console.log(value);
        const self = this;
        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            typing: false,
            typingTimeout: setTimeout(function () {
                for(let i = 0 ; i<self.props.Filters.length;i++){
                    if (self.props.Filters[i].name.toLowerCase() === self.state.id.toLowerCase()){
                        self.props.Filters[i].filters[j].filter = value;
                        if (value!==null && value !=undefined){
                            //self.props.minGradient = value[0];
                            //self.props.maxGradient = value[1];
                            //self.props.colorChange(name)
                        }
                        else{
                            console.log(self.props)
                            self.props.setMinGradient(undefined);
                            self.props.setMaxGradient(undefined);
                        }
                        self.props.handle();
                    }
                }
            }, 150)
        });
        this.setState({sliderValue:value})
    };
    handleSliderFilter = (value,name) => {
        console.log(this.props.Filters)
        const self = this;
        if (self.state.typingTimeout2) {
            clearTimeout(self.state.typingTimeout2);
        }

        self.setState({
            typing2: false,
            typingTimeout2: setTimeout(function () {
                let triger = false;
                console.log(self.props.Filters)
                for(let i = 0 ; i<self.props.Filters.length;i++){
                    console.log(value,self.state.id,self.props.Filters[i].name,name)
                    if (self.props.Filters[i].name.toLowerCase() === self.state.id.toLowerCase()){
                        for ( let j = 0 ; j < self.props.Filters[i].filters.length;j++){
                            if (self.props.Filters[i].filters[j].name === name ) {
                                triger = true;
                                console.log(self.props.Filters[i].name,name)
                                self.props.Filters[i].filters[j].filter = null;
                                self.props.handle();
                                self.handleSlider(value , j , name);
                            }

                        }
                        if(triger === false) {
                            console.log(self.props.Filters[i].name,name)
                            self.props.Filters[i].filters[1].filter = null;
                            self.props.handle();
                            self.handleSlider(value,1, name);
                        }
                    }
                }
            }, 50)
        });
    };
    handleString = (value,name) => {
        console.log(value,this.props.FilterLayerData[this.state.value].data,this.props.value);
        const self = this;
        console.log()
        if (self.state.typingTimeout2) {
            clearTimeout(self.state.typingTimeout2);
        }
        for(let i = 0 ; i<this.props.Filters.length;i++){
            if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()){
                for (let j = 0 ; j< this.props.Filters[i].filters.length;j++)
                {
                    if (this.props.Filters[i].filters[j].name === name) {
                        this.props.Filters[i].filters[j].filter = null;
                        this.props.handle();
                    }
                }
            }
        }
        self.setState({
            typing2: false,
            typingTimeout2: setTimeout(function () {
                for(let i = 0 ; i<self.props.Filters.length;i++){
                    if (self.props.Filters[i].name.toLowerCase() === self.state.id.toLowerCase()){
                        for (let j = 0 ; j< self.props.Filters[i].filters.length;j++)
                        {
                            if (self.props.Filters[i].filters[j].name === name) {
                                self.props.Filters[i].filters[j].filter = value.value;
                            }
                        }
                    }
                }
                self.setState({valueLength:value.value.length});
                self.props.handle();
            }, 100)
        });

    };
    handleTree = (e,val) => {
        //console.log(e,val);

    };
    filterChronostrat = (value) => {
        for(let i = 0 ; i<this.props.Filters.length;i++){
            if (this.props.Filters[i].name.toLowerCase() === this.props.value.toLowerCase()){
                for (let j = 0;j<this.props.Filters[i].filters.length;j++){
                    if (this.props.Filters[i].filters[j].name === 'Стратиграфия'){
                        this.props.Filters[i].filters[j].filter = value;
                        this.props.handle();
                    }
                }
            }
        }
    };
    handleSelect = (val) => {
        //console.log(val)
        let value = {label:val};
        // value.label = val.name;
        //console.log(value);
        let index;
        for (let i = 0; i < this.props.FilterLayerData.length; i++) {
            if (this.props.FilterLayerData[i].name.toLowerCase() === this.props.value.toLowerCase()) {
                this.setState({value:i});
                index = i;
            }
        }
        //console.log(value,FilterLayerData[this.state.value].data);
        for (let i = 0; i < this.props.FilterLayerData[index].data.length; i++) {
            //console.log(FilterLayerData[i].name,this.state.id)
            if (this.props.FilterLayerData[index].data[i].name.toLowerCase() === value.label.toLowerCase()) {
                //console.log("success")
                if (this.props.FilterLayerData[index].data[i].display === "none") {
                    this.props.FilterLayerData[index].data[i].display = "block"
                }
                else
                    this.props.FilterLayerData[index].data[i].display = "none";
            }
        }
        this.props.handle();
    };
    handleNullSelect = (val) => {
        //console.log(val)
        let value = {label:val};
        // value.label = val.name;
        //console.log(value);
        let index;
        for (let i = 0; i < this.props.FilterLayerData.length; i++) {
            if (this.props.FilterLayerData[i].name.toLowerCase() === this.props.value.toLowerCase()) {
                this.setState({value:i});
                index = i;
            }
        }
        //console.log(value,FilterLayerData[this.state.value].data);
        for (let i = 0; i < this.props.FilterLayerData[index].data.length; i++) {
            //console.log(FilterLayerData[i].name,this.state.id)
            if (this.props.FilterLayerData[index].data[i].name.toLowerCase() === value.label.toLowerCase()) {
                //console.log("success")
                if (this.props.FilterLayerData[index].data[i].nulldisplay === "none") {
                    this.props.FilterLayerData[index].data[i].nulldisplay = "block"
                }
                else
                    this.props.FilterLayerData[index].data[i].nulldisplay = "none";
            }
        }
        this.props.handle();
    };
    handleReset = (val,arr,type) => {
        console.log(val);
        Array.from(document.querySelectorAll("input")).forEach(
            input => {
                if (arr.length > 1)
                {
                    if (input.id === arr[0] || input.id === arr[1])
                        input.value = ""
                }
                else{
                    if (input.id === arr[0])
                        input.value = ""
                }
            }
        );
        let value = {label:val};
        if (arr !== undefined)
            for (let i = 0 ;i<arr.length;i++ ){
                document.getElementById(arr[i]).value = '';
                console.log(arr[i],document.getElementById(arr[i]))
            }
        if (val === 'Классификация')
            value.label = "Класс"
        if (val === 'Год открытия')
            value.label = "Год"
        for(let i = 0 ; i<this.props.Filters.length;i++){
            if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()){
                for(let j = 0;j<this.props.Filters[i].filters.length;j++) {
                    //console.log(Filters[i].filters[j].name,value.label.toLowerCase());
                    if (this.props.Filters[i].filters[j].name.toLowerCase() === value.label.toLowerCase() ) {
                        //console.log(Filters[i].filters[j].filter)
                        this.props.Filters[i].filters[j].filter = null;
                    }

                }

            }
        }

        this.props.handle();
    };
    handleResetID = (val) =>{
        console.log(val)
        let checklist = this.state.checkedtest
        let key = val;
        if (key === "Классификация")
            key = "Класс"
        for(let i = 0 ; i<this.props.Filters.length;i++){
            if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()){
                //console.log(Filters[i].name,Filters[i])
                console.log(val,this.state.id.toLowerCase(),this.props.Filters[i].name.toLowerCase())
                for(let j = 0;j<this.props.Filters[i].filters.length;j++){
                    console.log(this.props.Filters[i].filters[j].name,val)
                    if ( this.props.Filters[i].filters[j].name === key){
                        console.log(val,key)
                        checklist[val] = [];
                        this.props.Filters[i].filters[j].filter = null;
                        this.setState({checkedtest:checklist})
                    }
                }


            }
        }
        //console.log(Filters)
        this.props.handle();
    }
    handleSetNullCheckBox = (checkBoxState,name,id) => {
        console.log(checkBoxState,name,id)
        for(let i = 0 ; i<this.props.Filters.length;i++){
            if (this.props.Filters[i].name.toLowerCase() === id.toLowerCase()){
                for(let j = 0;j<this.props.Filters[i].filters.length;j++) {
                    //console.log(Filters[i].filters[j].name,value.label.toLowerCase());
                    if (name.toLowerCase() === "классификация")
                        name =  "Класс";
                    if (this.props.Filters[i].filters[j].name.toLowerCase() === name.toLowerCase() ) {
                        //console.log(Filters[i].filters[j].filter)
                        console.log(this.props.Filters[i].filters[j]);
                        this.props.Filters[i].filters[j].notnull = checkBoxState;
                    }

                }

            }
        }
        this.props.handle();
    };
    handleContextmenu = (e,name) =>{
        e.preventDefault()
        let container = document.getElementsByClassName('control-panel')[0]
        console.log(container)
        let rect = container.getBoundingClientRect();
        let x = e.clientX + container.scrollLeft - rect.left;
        let y = e.clientY + container.scrollTop - rect.top;
        this.setState({mapMenuDisplay:"block",mapMenuName:name, mapMenuPosition:{y:y,x:x}})
    }
    handleResetAll = (name) => {
        //console.log(Filters)
        for(let i = 0 ; i<this.props.Filters.length;i++){
            if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()){
                //console.log(Filters[i].name,Filters[i])
                for(let j = 0;j<this.props.Filters[i].filters.length;j++)
                    this.props.Filters[i].filters[j].filter = null;
            }
        }
        //console.log(Filters)
        this.props.handle();
    };
    render () {
        let textselect;
        let value;
        for (let i = 0; i < this.props.FilterLayerData.length; i++) {
            if (this.props.FilterLayerData[i].name.toLowerCase() === this.state.id.toLowerCase()) {
                textselect = this.props.FilterLayerData[i].list;
                value = i;
            }
        }
        console.log(this.props.FilterLayerData[value])
        if (this.props.FilterLayerData[value] !== undefined)
            return (
                <div id={this.state.id} >
                    <Checkbox
                        slider
                        checked={this.state.active}
                        size={'mini'}
                        className={'checkbox'}
                        label={this.state.title}
                        onChange={ () => {
                            for(let i = 0 ; i<this.props.Filters.length;i++){
                                if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()){
                                    console.log("Switch ",this.props.Filters[i].switch)
                                    if (this.props.Filters[i].switch === false) {
                                        this.props.Filters[i].switch = !this.props.Filters[i].switch;
                                        this.props.handle();
                                        this.setState({active:true,title:"Or"})
                                    }
                                    else {
                                        this.props.Filters[i].switch = !this.props.Filters[i].switch;
                                        this.props.handle();
                                        this.setState({active:false,title:"And"})
                                    }

                                }}
                        }}/>
                    {(this.state.mapMenuDisplay==="block")&&(
                        <div style={{top: this.state.mapMenuPosition.y, left: this.state.mapMenuPosition.x
                        }} className="geocodingContextMenu" onMouseLeave={()=>{
                            this.setState({mapMenuDisplay:"none"})
                        }}>
                            <div className={"menuButton"}  style={{border: '1px solid black', backgroundColor: 'rgba(255, 255, 255, 0.85)'}} onClick={()=>{
                                this.handleNullSelect(this.state.mapMenuName)
                                this.setState({mapMenuDisplay:"none"})
                            }}>{i18n.t('Data')}</div>

                        </div>
                    )}
                    <div className={'legends'} style={{display:this.state.infoDisplay,left:this.state.x+"px",top:this.state.y+"px"}}>
                        <label>{i18n.t("Классификация месторождений нефти ")}</label>
                        <br/>
                        <label>{i18n.t("и газа")}</label>
                        <br/>
                        <label> {i18n.t("По величине извлекаемых запасов:")}</label>
                        <br/>
                        <label> {i18n.t("уникальные — более 300 млн т нефти ")}</label>
                        <br/>
                        <label>{i18n.t("или 300 млрд м³ газа;")}</label>
                        <br/>
                        <label>{i18n.t("крупные — от 30 до 300 млн т нефти")}</label>
                        <br/>
                        <label> {i18n.t("или от 30 до 300 млрд м³ газа;")}</label>
                        <br/>
                        <label>{i18n.t("средние — от 3 до 30 млн т нефти ")}</label>
                        <br/>
                        <label>{i18n.t("или от 3 до 30 млрд м³ газа;")}</label>
                        <br/>
                        <label>{i18n.t("мелкие — от 1 до 3 млн т нефти ")}</label>
                        <br/>
                        <label>{i18n.t("или от 1 до 3 млрд м³ газа;")}</label>
                        <br/>
                        <label>{i18n.t("очень мелкие — менее 1 млн т")}</label>
                        <br/>
                        <label> {i18n.t("нефти, менее 1 млрд м³ газа")}</label>
                    </div>
                    {this.props.FilterLayerData[value].data.map(({name,type,word,display,nulldisplay},index) => {
                        //Dropdown options={this.state.data.map(data => ({key: data.name, text:data.name, value: data.name}))} onChange={this._onSelect} value={this.state.selected} fluid selection placeholder="Выбирите Слой" />
                        let FilterButtonProps = {basic:true}
                        let filterApplyed = false
                        for (let i = 0; i < this.props.Filters.length; i++) {
                            console.log(filterApplyed,name.toLowerCase(),this.state.id.toLowerCase())
                            if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase())
                                for (let j = 0; j < this.props.Filters[i].filters.length; j++) {
                                    console.log(filterApplyed,name.toLowerCase(),this.state.id.toLowerCase())
                                    if (this.props.Filters[i].filters[j].name.toLowerCase() === name.toLowerCase())
                                        if  (this.props.Filters[i].filters[j].filter !== null && this.props.Filters[i].filters[j].filter !==""){
                                            filterApplyed = true;
                                            FilterButtonProps = {basic:false,color:"green"}
                                        }


                                }
                        }
                        if (type === 'text'){
                            //  console.log("STRING")
                            let iconnull = "none";
                            let iconname = 'plus';
                            if(display === "none")
                                iconname = 'plus';
                            else
                                iconname = 'minus';
                            return (
                                <div>
                                    <Label {...FilterButtonProps} onContextMenu={(e)=>this.handleContextmenu(e,name)} onClick={() => this.handleSelect(name)} size={'medium'}>
                                        <Icon name={iconname} /> {i18n.t(name)} {(filterApplyed)&&(<Icon name={"check"} />)}
                                    </Label>
                                    <div id ={this.state.id + name} style={{display:display }}>

                                        <FilterCheckBoxGroup display={nulldisplay} name={name} id={this.state.id} handler={this.handleSetNullCheckBox}/>
                                        <Input
                                            id={this.state.id + name+1}
                                            onChange={(e,value) => {
                                                this.handleString(value,name)
                                            }}
                                        />
                                        <Button style={{transform: "scale(0.815)"}} basic onClick={() => this.handleReset(name,[this.state.id + name+1])} size={'medium'}>{"Reset"}</Button>
                                    </div>
                                </div>
                            )
                        }
                        else if (type === 'geometry'){
                            //  console.log("STRING")
                            let iconnull = "none";
                            let iconname = 'plus';
                            if(display === "none")
                                iconname = 'plus';
                            else
                                iconname = 'minus';
                            return (<>
                                <Label {...FilterButtonProps} onContextMenu={(e)=>this.handleContextmenu(e,name)} onClick={() => this.handleSelect(name)} size={'medium'}>
                                    <Icon name={iconname} /> {i18n.t(name)} {(filterApplyed)&&(<Icon name={"check"} />)}
                                </Label>
                                <div id ={this.state.id + name} style={{display:display }}>
                                    <GeometryFilterTranslate filterhandler={(val)=>{
                                        for (let i = 0; i < this.props.Filters.length; i++) {
                                            if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()) {
                                                for (let j = 0; j < this.props.Filters[i].filters.length; j++) {
                                                    if (this.props.Filters[i].filters[j].name.toLowerCase() === name.toLowerCase()) {
                                                        console.log(val)
                                                        this.props.Filters[i].filters[j].filter = val;
                                                        this.props.handle();
                                                    }
                                                }
                                            }
                                        }
                                    }} id={this.state.id } name={name}/>
                                    <Button style={{transform: "scale(0.815)"}} basic size={'medium'} onClick={() => this.handleReset(name,[this.state.id + name+1])}>{"Reset"}</Button>
                                </div>
                                <br/>
                            </>)
                        }
                        else if (type === 'interaction'){
                            //  console.log("STRING")
                            let iconnull = "none";
                            let iconname = 'plus';
                            if(display === "none")
                                iconname = 'plus';
                            else
                                iconname = 'minus';
                            return (<>
                                <Label {...FilterButtonProps} onContextMenu={(e)=>this.handleContextmenu(e,name)} onClick={() => this.handleSelect(name)} size={'medium'}>
                                    <Icon name={iconname} /> {i18n.t(name)} {(filterApplyed)&&(<Icon name={"check"} />)}
                                </Label>
                                <div id ={this.state.id + name} style={{display:display }}>
                                    <InteractionFilter id={this.state.id} setinteraction={this.props.setinteraction} />
                                    <br/>
                                    <Button style={{transform: "scale(0.815)"}} basic size={'medium'} onClick={() => {
                                        for (let i = 0; i < this.props.Filters.length; i++) {
                                            if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()) {
                                                for (let j = 0; j < this.props.Filters[i].filters.length; j++) {
                                                    if (this.props.Filters[i].filters[j].name.toLowerCase() === name.toLowerCase()) {
                                                        this.props.Filters[i].filters[j].reverse = !this.props.Filters[i].filters[j].reverse;
                                                        this.props.handle();
                                                    }
                                                }
                                            }
                                        }
                                    }}>{"Reverse"}</Button><Button style={{transform: "scale(0.815)"}} basic size={'medium'} onClick={() => this.handleResetID(name)}>{"Reset"}</Button>
                                </div>
                                <br/>
                            </>)
                        }
                        else if (type === 'age'){
                              console.log(name,type,word,display,nulldisplay,this.props.FilterLayerData[value].data[index])
                            let iconnull = "none";
                            let iconname = 'plus';
                            if(display === "none")
                                iconname = 'plus';
                            else
                                iconname = 'minus';
                            return (<>
                                <Label {...FilterButtonProps} onContextMenu={(e)=>this.handleContextmenu(e,name)} onClick={() => this.handleSelect(name)} size={'medium'}>
                                    <Icon name={iconname} /> {i18n.t(name)} {(filterApplyed)&&(<Icon name={"check"} />)}
                                </Label>
                                <div id ={this.state.id + name} style={{display:display }}>
                                    <AgeFilterTranslate  word={word} age_word={this.props.age_word} age_period={this.props.age_period} filterhandler={(val)=>{
                                        for (let i = 0; i < this.props.Filters.length; i++) {
                                            if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()) {
                                                for (let j = 0; j < this.props.Filters[i].filters.length; j++) {
                                                    if (this.props.Filters[i].filters[j].name.toLowerCase() === name.toLowerCase()) {
                                                        console.log(val)
                                                        console.log(this.props.Filters[i],this.props.Filters[i].filters[j])
                                                        this.props.Filters[i].filters[j].filter = val;
                                                        this.props.handle();
                                                    }
                                                }
                                            }
                                        }
                                    }} id={this.state.id } name={name}/>
                                    <Button style={{transform: "scale(0.815)"}} basic size={'medium'} onClick={() => this.handleReset(name,[this.state.id + name+1])}>{"Reset"}</Button>
                                </div>
                                <br/>
                            </>)
                        }
                        else if (type === 'number') {
                            let iconname = 'plus';
                            let min = 1800;
                            let max = new Date().getFullYear();
                            let title = i18n.t(name);
                            let histogramm = null;
                            let layout={height: 0,
                                width: 0,}
                            var GraphicsData = null ;
                            let graphEnable = false;
                            //
                            //
                            if (name ==="Максимальная высота") {
                                min = 0;
                                max = 500;
                                title = i18n.t(name) + " м."
                            }
                            if (name ==="emissions_value") {
                                min = 0;
                                max = 96578767
                                title = i18n.t(name) + " "
                            }
                            if (name ==="Платеж победителя") {
                                min = 0;
                                max = this.props.maxWinValue;
                                title = i18n.t(name) ;
                                histogramm =this.props.histogramValue;
                                graphEnable = true;
                                let x = [];
                                let y = [];
                                for (let i = 0 ; i<histogramm.get_graph_data_test.aggregated_data.length;i++){
                                    y.push(histogramm.get_graph_data_test.aggregated_data[i].count)
                                    for ( let j = 0;j< histogramm.get_graph_data_test.aggregated_data[i].count;j++){
                                        if (histogramm.get_graph_data_test.aggregated_data[i].name_ru !== null && histogramm.get_graph_data_test.aggregated_data[i].name_ru !== 0)
                                            x.push(histogramm.get_graph_data_test.aggregated_data[i].name_ru)
                                    }
                                }
                                GraphicsData = [
                                    {
                                        x: x,
                                        type: 'histogram',
                                        opacity: 0.5,
                                    }
                                ];
                                layout = {height: 250,
                                    width: 400,
                                    showlegend: false,
                                    title:name,};
                            }
                            if (name ==="Начальная цена аукциона") {
                                min = 0;
                                max = this.props.maxAuctionValue;
                                title = i18n.t(name) ;
                                histogramm =this.props.histogramValue2;
                                graphEnable = true;
                                let x = [];
                                let y = [];
                                for (let i = 0 ; i<histogramm.get_graph_data_test.aggregated_data.length;i++){
                                    y.push(histogramm.get_graph_data_test.aggregated_data[i].count)
                                    for ( let j = 0;j< histogramm.get_graph_data_test.aggregated_data[i].count;j++){
                                        if (histogramm.get_graph_data_test.aggregated_data[i].name_ru !== null && histogramm.get_graph_data_test.aggregated_data[i].name_ru !== 0)
                                            x.push(histogramm.get_graph_data_test.aggregated_data[i].name_ru)
                                    }
                                }
                                GraphicsData = [
                                    {
                                        x: x,
                                        type: 'histogram',
                                        opacity: 0.5,

                                    }
                                ];
                                layout = {height: 250,
                                    width: 400,
                                    showlegend: false,
                                    title:name,};
                            }
                            if (name ==="Максимальная глубина воды") {
                                min = 0;
                                max = 2000;
                                title = i18n.t(name) + " м."
                            }
                            if(display === "none")
                                iconname = 'plus';
                            else
                                iconname = 'minus';
                            return (
                                <div>
                                    <Label {...FilterButtonProps} onContextMenu={(e)=>this.handleContextmenu(e,name)}   onClick={() => this.handleSelect(name)} size={'medium'}>
                                        <Icon name={iconname} /> {title} {(filterApplyed)&&(<Icon name={"check"} />)}
                                    </Label>
                                    <div id ={this.state.id + name} style={{display:display }}>
                                        <FilterCheckBoxGroup display={nulldisplay} name={name} id={this.state.id} handler={this.handleSetNullCheckBox}/>
                                        <br/>
                                        <FilterRangeSlider histogram={histogramm} min={min} max={max} handle={this.handleSlider} handle2={this.handleSliderFilter} name ={name} />
                                        <br/>
                                        {(graphEnable)&&(
                                            <div style={{marginLeft: "-68px"}}>
                                                <Plot
                                                    style={{opacity:"0.8"}}
                                                    data={GraphicsData}
                                                    layout={layout}/>
                                                <br/>
                                            </div>)}
                                    </div>
                                </div>
                            )
                        }
                        else if (type === 'date') {
                            console.log(name,type);
                            let iconname = 'plus';
                            if(display === "none")
                                iconname = 'plus';
                            else
                                iconname = 'minus';
                            return (
                                <div>
                                    <Label {...FilterButtonProps} onContextMenu={(e)=>this.handleContextmenu(e,name)} size={'medium'} onClick={() => this.handleSelect(name)}>
                                        <Icon name={iconname} /> {i18n.t(name)} {(filterApplyed)&&(<Icon name={"check"} />)}
                                    </Label>
                                    <div id ={this.state.id + name} style={{display:display }}>
                                        <FilterCheckBoxGroup display={nulldisplay} name={name} id={this.state.id} handler={this.handleSetNullCheckBox}/>
                                        <Label basic size={'medium'}>{"From"}</Label>
                                        <DatePicker
                                            selected={this.state.datetest[name].start}
                                            dateFormat="yyyy/MM/dd"
                                            onChange={selected => {
                                                console.log(selected,this.state.datetest,this.state.datetest[name]);
                                                for(let i = 0 ; i<this.props.Filters.length;i++){
                                                    if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()){
                                                        for ( let j = 0 ; j < this.props.Filters[i].filters.length;j++){
                                                            if (this.props.Filters[i].filters[j].name === name) {
                                                                if (this.props.Filters[i].filters[j].filter === null) {
                                                                    console.log(this.props.Filters[i].filters[j][selected.getTime(),selected.getTime()])
                                                                    this.props.Filters[i].filters[j].filter = [selected.getTime(),selected.getTime()];
                                                                }
                                                                console.log(this.props.Filters[i].filters[j],selected.getTime())
                                                                this.props.Filters[i].filters[j].filter[0] = selected.getTime();
                                                            }

                                                        }
                                                    }
                                                }
                                                this.setState(prevState => {
                                                    let datetest = Object.assign({}, prevState.datetest);  // creating copy of state variable jasper
                                                    datetest[name].start = selected;                             // update the name property, assign a new value
                                                    return { datetest };                                         // return new object jasper object
                                                });
                                                this.props.handle();
                                            }
                                            }
                                        />
                                        <br/>
                                        <Label basic size={'medium'}>{"To  "}</Label>
                                        <DatePicker
                                            selected={this.state.datetest[name].end}
                                            dateFormat="yyyy/MM/dd"
                                            onChange={selected => {
                                                //console.log(selected,this.state.datetest,this.state.datetest[name])
                                                for(let i = 0 ; i<this.props.Filters.length;i++){
                                                    if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()){
                                                        for ( let j = 0 ; j < this.props.Filters[i].filters.length;j++) {
                                                            if (this.props.Filters[i].filters[j].name === name) {
                                                                if (this.props.Filters[i].filters[j].filter === null)
                                                                    this.props.Filters[i].filters[j].filter = [0, selected.getTime()];
                                                                this.props.Filters[i].filters[j].filter[1] = selected.getTime();
                                                            }
                                                        }
                                                    }
                                                }
                                                this.setState(prevState => {
                                                    let datetest = Object.assign({}, prevState.datetest);  // creating copy of state variable jasper
                                                    datetest[name].end = selected;                     // update the name property, assign a new value
                                                    return { datetest };                                 // return new object jasper object
                                                });
                                                this.props.handle();
                                            }
                                            }
                                        />

                                    </div>
                                </div>
                            )
                        }
                        else if (type === 'word') {
                            console.log(word,name,this.state.id)
                            let info = false;
                            //console.log(word,name)
                            let active = false;
                            let iconname = 'plus';
                            if(display === "none")
                                iconname = 'plus';
                            else
                                iconname = 'minus';
                            if (name === "Классификация")
                                info = true
                            console.log(name,word)
                            if ( i18n.t("lang") === "en"){
                                let temp_word = []
                                for (let i = 0 ; i <word.length; i++){
                                    if (word[i].label_en !== undefined)
                                        temp_word.push({label:word[i].label_en,value:word[i].value})
                                    else
                                        temp_word.push({label:word[i].label,value:word[i].value})
                                }
                                word = temp_word;
                            }
                            console.log(word ,name,type,word,display,nulldisplay)
                            if (word !== null && word !== undefined)
                                if( word.length >1 )
                                return (
                                    <div>
                                        <Label {...FilterButtonProps} onContextMenu={(e)=>this.handleContextmenu(e,name)} size={'medium'} onClick={() => this.handleSelect(name)}>
                                            <Icon name={iconname} /> {i18n.t(name)} {(filterApplyed)&&(<Icon name={"check"} />)}
                                        </Label>
                                        {info && (
                                            <Popup style={{width:300,zIndex:999999999,textAlign:"left"}} position='top center' trigger={<Icon circular floated='left'  name={"info"}/>}  flowing>
                                                {i18n.t("Классификация месторождений нефти и газа. ")}<br/>
                                                {i18n.t("По величине извлекаемых запасов:")}<br/>
                                                {i18n.t("уникальные — более 300 млн т нефти или 300 млрд м³ газа;")}<br/>
                                                {i18n.t("крупные — от 30 до 300 млн т нефти или от 30 до 300 млрд м³ газа;")}<br/>
                                                {i18n.t("средние — от 3 до 30 млн т нефти или от 3 до 30 млрд м³ газа; или от 3 до 30 млрд м³ газа;")}<br/>
                                                {i18n.t("мелкие — от 1 до 3 млн т нефти или от 1 до 3 млрд м³ газа;")}<br/>
                                                {i18n.t("очень мелкие — менее 1 млн т нефти, менее 1 млрд м³ газа;")}
                                            </Popup>
                                        )}
                                        <div id ={this.state.id + name} style={{display:display  }}>
                                            <FilterCheckBoxGroup display={nulldisplay} name={name} id={this.state.id} handler={this.handleSetNullCheckBox}/>
                                            {console.log(word,this.state.checkedtest[name],name,this.state.checkedtest,word[0].value,typeof word[0].value)}
                                            <CheckboxTree
                                                style={{display:'block'}}
                                                nodes={word}
                                                iconsClass="fa5"
                                                checked={this.state.checkedtest[name]}
                                                expanded={this.state.expanded}
                                                onExpand={expanded => this.setState({ expanded })}
                                                onClick={this.handleTree}
                                                onCheck={checked => {
                                                    //console.log(this.state.checkedtest[name],this.state.checkedtest)
                                                    //console.log(checked,this.state.checkedtest,this.state.checkedtest[name])
                                                    if (name === "Возраст") {
                                                        for (let i = 0; i < this.props.Filters.length; i++) {
                                                            if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()) {
                                                                if (checked.length === 0)
                                                                    this.props.Filters[i].filters[3].filter = null
                                                                else
                                                                    this.props.Filters[i].filters[3].filter = checked;
                                                            }
                                                        }
                                                    }
                                                    else if (name === "Тип полезных ископаемых"){
                                                        for (let i = 0; i < this.props.Filters.length; i++) {
                                                            if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()) {
                                                                //console.log(Filters[i].filters[6],checked);
                                                                if (checked.length === 0)
                                                                    this.props.Filters[i].filters[5].filter = null;
                                                                else
                                                                    this.props.Filters[i].filters[5].filter = checked;
                                                            }
                                                        }
                                                    }
                                                    else if (name === "Расположение"){
                                                        for (let i = 0; i < this.props.Filters.length; i++) {
                                                            if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()) {
                                                                //console.log(Filters[i].filters[2],checked);
                                                                if (checked.length === 0)
                                                                    this.props.Filters[i].filters[3].filter = null;
                                                                else
                                                                    this.props.Filters[i].filters[3].filter = checked;
                                                            }
                                                        }
                                                    }
                                                    else if (name === "ProtectedAreasType"){
                                                        for (let i = 0; i < this.props.Filters.length; i++) {
                                                            if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()) {
                                                                //console.log(Filters[i].filters[2],checked);
                                                                if (checked.length === 0)
                                                                    this.props.Filters[i].filters[1].filter = null;
                                                                else
                                                                    this.props.Filters[i].filters[1].filter = checked;
                                                            }
                                                        }
                                                    }
                                                    else if (name === "ProtectedAreasProfile"){
                                                        for (let i = 0; i < this.props.Filters.length; i++) {
                                                            if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()) {
                                                                //console.log(Filters[i].filters[2],checked);
                                                                if (checked.length === 0)
                                                                    this.props.Filters[i].filters[2].filter = null;
                                                                else
                                                                    this.props.Filters[i].filters[2].filter = checked;
                                                            }
                                                        }
                                                    }
                                                    else if (name === "domain"){
                                                        for (let i = 0; i < this.props.Filters.length; i++) {
                                                            if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()) {
                                                                //console.log(Filters[i].filters[2],checked);
                                                                if (checked.length === 0)
                                                                    this.props.Filters[i].filters[1].filter = null;
                                                                else
                                                                    this.props.Filters[i].filters[1].filter = checked;
                                                            }
                                                        }
                                                    }
                                                    else if (name === "seismic_type"){
                                                        for (let i = 0; i < this.props.Filters.length; i++) {
                                                            if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()) {
                                                                //console.log(Filters[i].filters[2],checked);
                                                                if (checked.length === 0)
                                                                    this.props.Filters[i].filters[1].filter = null;
                                                                else
                                                                    this.props.Filters[i].filters[1].filter = checked;
                                                            }
                                                        }
                                                    }
                                                    else if (name === "signal_source"){
                                                        for (let i = 0; i < this.props.Filters.length; i++) {
                                                            if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()) {
                                                                //console.log(Filters[i].filters[2],checked);
                                                                if (checked.length === 0)
                                                                    this.props.Filters[i].filters[1].filter = null;
                                                                else
                                                                    this.props.Filters[i].filters[1].filter = checked;
                                                            }
                                                        }
                                                    }
                                                    else if (name === "Метод открытия"){
                                                        for (let i = 0; i < this.props.Filters.length; i++) {
                                                            if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()) {
                                                                //console.log(Filters[i].filters[2],checked);
                                                                if (checked.length === 0)
                                                                    this.props.Filters[i].filters[9].filter = null;
                                                                else
                                                                    this.props.Filters[i].filters[9].filter = checked;
                                                            }
                                                        }
                                                    }
                                                    else if (name === "Тип"){
                                                        for (let i = 0; i < this.props.Filters.length; i++) {
                                                            if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()) {
                                                                //console.log(Filters[i].filters[4],checked);
                                                                if (checked.length === 0)
                                                                    this.props.Filters[i].filters[0].filter = null;
                                                                else
                                                                    this.props.Filters[i].filters[0].filter = checked;
                                                            }
                                                        }
                                                    }
                                                    else if (name === "Местность"){
                                                        for (let i = 0; i < this.props.Filters.length; i++) {
                                                            if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()) {
                                                                //console.log(Filters[i].filters[5],checked);
                                                                if (checked.length === 0)
                                                                    this.props.Filters[i].filters[5].filter = null;
                                                                else
                                                                    this.props.Filters[i].filters[5].filter = checked;
                                                            }
                                                        }
                                                    }
                                                    else if (name === "Степень Уверенности"){
                                                        for (let i = 0; i < this.props.Filters.length; i++) {
                                                            if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()) {
                                                                //console.log(Filters[i].filters[8],Filters);
                                                                if (checked.length === 0)
                                                                    this.props.Filters[i].filters[8].filter = null;
                                                                else
                                                                    this.props.Filters[i].filters[8].filter = checked;
                                                            }
                                                        }
                                                    }
                                                    else if (name === "Статус лицензии"){
                                                        for (let i = 0; i < this.props.Filters.length; i++) {
                                                            if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()) {
                                                                //console.log(Filters[i].filters[3],checked);
                                                                if (checked.length === 0)
                                                                    this.props.Filters[i].filters[0].filter = null;
                                                                else
                                                                    this.props.Filters[i].filters[0].filter = checked;
                                                            }
                                                        }
                                                    }
                                                    else if (name === "Тип лицензии"){
                                                        for (let i = 0; i < this.props.Filters.length; i++) {
                                                            if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()) {
                                                                //console.log(Filters[i].filters[2],checked);
                                                                if (checked.length === 0)
                                                                    this.props.Filters[i].filters[4].filter = null;
                                                                else
                                                                    this.props.Filters[i].filters[4].filter = checked;
                                                            }
                                                        }
                                                    }
                                                    else if (name === "ObjectType"){
                                                        for (let i = 0; i < this.props.Filters.length; i++) {
                                                            if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()) {
                                                                //console.log(Filters[i].filters[2],checked);
                                                                if (checked.length === 0)
                                                                    this.props.Filters[i].filters[1].filter = null;
                                                                else
                                                                    this.props.Filters[i].filters[1].filter = checked;
                                                            }
                                                        }
                                                    }
                                                    else if (name === "Outcrop"){
                                                        for (let i = 0; i < this.props.Filters.length; i++) {
                                                            if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()) {
                                                                //console.log(Filters[i].filters[2],checked);
                                                                if (checked.length === 0)
                                                                    this.props.Filters[i].filters[2].filter = null;
                                                                else
                                                                    this.props.Filters[i].filters[2].filter = checked;
                                                            }
                                                        }
                                                    }
                                                    else if (name === "Category"){
                                                        for (let i = 0; i < this.props.Filters.length; i++) {
                                                            if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()) {
                                                                //console.log(Filters[i].filters[2],checked);
                                                                if (checked.length === 0)
                                                                    this.props.Filters[i].filters[1].filter = null;
                                                                else
                                                                    this.props.Filters[i].filters[1].filter = checked;
                                                            }
                                                        }
                                                    }
                                                    else if (name === "Subcategory"){
                                                        for (let i = 0; i < this.props.Filters.length; i++) {
                                                            if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()) {
                                                                //console.log(Filters[i].filters[2],checked);
                                                                if (checked.length === 0)
                                                                    this.props.Filters[i].filters[2].filter = null;
                                                                else
                                                                    this.props.Filters[i].filters[2].filter = checked;
                                                            }
                                                        }
                                                    }
                                                    else if (name === "Классификация"){
                                                        for (let i = 0; i < this.props.Filters.length; i++) {
                                                            if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()) {
                                                                //console.log(Filters[i].filters[2],checked);
                                                                if (checked.length === 0)
                                                                    this.props.Filters[i].filters[1].filter = null;
                                                                else
                                                                    this.props.Filters[i].filters[1].filter = checked;
                                                            }
                                                        }
                                                    }
                                                    else {
                                                        for (let i = 0; i < this.props.Filters.length; i++) {
                                                            //console.log(this.props.Filters[i].name.toLowerCase(),this.state.id.toLowerCase())
                                                            if (this.props.Filters[i].name.toLowerCase() === this.state.id.toLowerCase()) {
                                                                console.log(this.props.Filters[i].name.toLowerCase(),this.state.id.toLowerCase())
                                                                for (let j = 0 ;j < this.props.Filters[i].filters.length;j++) {
                                                                    if (this.props.Filters[i].filters[j].name === name){
                                                                        if (checked.length === 0)
                                                                            this.props.Filters[i].filters[j].filter = null
                                                                        else
                                                                            this.props.Filters[i].filters[j].filter = checked;
                                                                    }

                                                                }
                                                                //if (checked.length === 0)
                                                                //    this.props.Filters[i].filters[2].filter = null
                                                                //else
                                                                //    this.props.Filters[i].filters[2].filter = checked;
                                                            }
                                                        }
                                                    }
                                                    this.props.handle();
                                                    this.setState(prevState => {
                                                        let checkedtest = Object.assign({}, prevState.checkedtest);  // creating copy of state variable jasper
                                                        checkedtest[name] = checked;                     // update the name property, assign a new value
                                                        return { checkedtest };                                 // return new object jasper object
                                                    })
                                                    // this.setState({ checkedtest:{[name]:checked }})
                                                }
                                                }

                                            />
                                            <Button style={{transform: "scale(0.815)"}} basic size={'medium'} onClick={() => this.handleResetID(name)}>{"Reset"}</Button>

                                        </div>
                                    </div>
                                )
                        }
                        else if (type === 'chrono'){
                            let iconname = 'plus';
                            if(display === "none")
                                iconname = 'plus';
                            else
                                iconname = 'minus';
                            return (
                                <div>
                                    <Label {...FilterButtonProps} onContextMenu={(e)=>this.handleContextmenu(e,name)} size={'medium'} onClick={() => this.handleSelect(name)}>
                                        <Icon name={iconname} /> {i18n.t(name)} {(filterApplyed)&&(<Icon name={"check"} />)}
                                    </Label>
                                    <div id ={this.state.id + name} style={{display:display }}>
                                        <FilterCheckBoxGroup display={nulldisplay} name={name} id={this.state.id} handler={this.handleSetNullCheckBox}/>
                                        <br/>
                                        <Chronostrat handle={this.filterChronostrat} word={word} age_word={this.props.age_word} age_period={this.props.age_period} />
                                    </div>
                                </div>
                            )
                        }
                    })}
                    <Button basic onClick={() => this.handleResetAll(this.state.id.toLowerCase())} size={'medium'}>{"Reset All"}</Button>
                </div>

            )
        else
            return (<div>{""}</div>)
    }
}
export default LayerFilters;
import {useEffect, useRef, useState} from "react";
import {getUniqueFeatures, normalize} from "../functions";
import Cookies from "js-cookie";
import {only_my} from "../VariableInitialize";

export default function MyGeoMapFilter(props) {

    useEffect(() => {

        let layerID = '';
        let layerTextID = '';
        let features ;
        let FilterPropsList = [{ empty:"table" }];
        let filterPropsInit = false;
        let filterPropsDisplay = "none";
        if (props.map.current !== null && props.map.current !== "null")
            setTimeout(() => {
                //console.log( props.Filters, props.result,props.data2,props.handleAlert)
                const map = props.map.current;
                let sources = map.style.sourceCaches;
                for (let i = 0; i < props.result.length; i++) {
                    if (props.result[i].visibility === "visible" && props.result[i].type !== "geojson" && props.result[i].type !== "image" && props.result[i].type !== "heatmap" && props.result[i].type !== "bubble" && props.result[i].type !== "wms") {
                        let sourceKeys = Object.keys(sources);
                        for (let j = 0; j < sourceKeys.length; j++) {
                            if (sourceKeys[j].includes(props.result[i].name) && !sourceKeys[j].includes("geojson") && !sourceKeys[j].includes("Heatmap") && !sourceKeys[j].includes("Bubble") && props.result[i].type !== "wms") {
                                if (sourceKeys[j] !== props.result[i].id) {
                                    if (props.result[i].name === "Basins"){
                                        if (!sourceKeys[j].includes("SubBasins")){
                                            map.removeLayer(sourceKeys[j])
                                            map.removeSource(sourceKeys[j])
                                        }
                                    }
                                    else {
                                        map.removeLayer(sourceKeys[j])
                                        map.removeSource(sourceKeys[j])
                                    }
                                }
                            }
                        }
                    }
                }
                    let Filterset = ["all"];
                    if (props.Filters.switch === false) {
                        Filterset = ["all"];
                    } else
                        Filterset = ["any"];
                    let layerName = "";
                    let layerText = "";
                    let layerHeat= "";
                    let layerBubble="";
                    let isnull = true;
                    let cont = false;
                    let layerSet = [...props.result];
                    //if (props.Filters.name === "GeologicObjects")
                    //    isnull = false;
                    for (let j = 0; j < layerSet.length; j++) {
                        if (layerSet[j].name === props.Filters.name)
                            if (layerSet[j].visibility !== "none") {
                                layerID = layerSet[j].id.toString();
                                layerName = layerSet[j].name;
                                layerText = layerName + "Text";
                                for (let k = 0; k < props.data2.length; k++) {
                                    if (props.data2[k].name === layerText)
                                        layerTextID = props.data2[k].id.toString();
                                }
                                for (let v = 0; v < props.result.length; v++) {
                                    if (props.result[v].name.includes(layerName) && props.result[v].name.includes("Heatmap"))
                                        layerHeat = props.result[v].id.toString();
                                }
                                for (let v = 0; v < props.result.length; v++) {
                                    if (props.result[v].name.includes(layerName) && props.result[v].name.includes("Bubble"))
                                        layerBubble = props.result[v].id.toString();
                                }
                            } else {
                                cont = true;
                            }
                    }
                    if (cont === true)
                        return;
                    if (props.lassoFilter.length > 0){
                        isnull = false;
                        Filterset.push([
                            'match',
                            ['get', 'qc_id'],
                            props.lassoFilter,
                            true,
                            false
                        ])
                    }
                    //console.log(layerID)
                    features = map.queryRenderedFeatures({layers: [layerID.toString()]});
                    console.log(layerID,features)
                    for (let j = 0; j < props.Filters.filters.length; j++) {
                        //console.log(features)
                        if (features) {
                            //console.log("features true")
                            var filtered;
                            var filtered2;
                            // Filter visible features that don't match the input value.
                            if (props.Filters.filters[j].type === 'text' && props.Filters.filters[j].filter !== null) {
                                isnull = false;
                                let multiple = false
                                var uniqueFeatures = getUniqueFeatures(features, props.Filters.filters[j].DBname);
                                //console.log(features,uniqueFeatures)
                                var value = normalize(props.Filters.filters[j].filter);
                                if (value.includes(',')){
                                    value = value.split(',');
                                    multiple = true;
                                }
                                filtered = uniqueFeatures.filter(function (feature) {
                                    //console.log(feature.properties[props.Filters.filters[j].DBname])
                                    if (feature.properties[props.Filters.filters[j].DBname] === undefined) {
                                        var name = "";
                                    } else
                                        var name = normalize(feature.properties[props.Filters.filters[j].DBname]);
                                    return name.indexOf(value) > -1;
                                });
                                if (filtered.length > 0) {
                                    filtered = getUniqueFeatures(filtered, props.Filters.filters[j].DBname);
                                }
                                //console.log(filtered,multiple,value);
                                if (!multiple)
                                    Filterset.push(
                                        [
                                            'in',
                                            value,
                                            ['downcase',['string', ['get', props.Filters.filters[j].DBname]]]
                                        ]);
                                else {
                                    let operatorSet = ['any']
                                    for (let m = 0;m < value.length;m++) {
                                        //console.log(value[m])
                                        operatorSet.push(
                                            [
                                                'in',
                                                value[m],
                                                ['downcase',['string', ['get', props.Filters.filters[j].DBname]]]
                                            ]);
                                    }
                                    Filterset.push(operatorSet);
                                }
                            }
                            if ((props.Filters.filters[j].name === 'FilterByGeometry' || props.Filters.filters[j].name === 'FilterByAge' || props.Filters.filters[j].name === 'FilterByReserves')&& props.Filters.filters[j].filter !== null){
                                //console.log(props.Filters.filters[j].filter,props.Filters.filters[j].name)
                                isnull = false;
                                if (props.Filters.filters[j].filter.length === 0){
                                    this.handleAlert("info","Не найдено ни одного объекта подходящего под условия запроса. ")
                                }
                                Filterset.push([
                                    'match',
                                    ['get', 'qc_id'],
                                    props.Filters.filters[j].filter,
                                    true,
                                    false
                                ])
                            }
                            if ((props.Filters.filters[j].name === 'FilterByInteraction')&& props.Filters.filters[j].filter !== null){
                                //console.log(props.Filters.filters[j].filter,props.Filters.filters[j].name)
                                isnull = false;
                                if (props.Filters.filters[j].filter.length === 0){
                                    //this.handleAlert("info","Не найдено ни одного объекта подходящего под условия запроса. ")
                                }
                                if (props.Filters.filters[j].reverse){
                                    Filterset.push([
                                        'match',
                                        ['get', 'qc_id'],
                                        props.Filters.filters[j].filter,
                                        true,
                                        false
                                    ])
                                }else
                                    Filterset.push([
                                        'match',
                                        ['get', 'qc_id'],
                                        props.Filters.filters[j].filter,
                                        false,
                                        true
                                    ])
                            }
                            if (props.Filters.filters[j].type === 'dict' && props.Filters.filters[j].filter !== null) {
                                //console.log(props.Filters.filters[j].type,props.Filters.filters[j].filter,props.Filters.filters[j].name)
                                isnull = false;
                                var value = props.Filters.filters[j].filter;
                                if ((props.Filters.filters[j].name === "Outcrop" || props.Filters.filters[j].name === "ObjectType")){
                                     let array = [];
                                     for (let k = 0; k < props.Filters.filters[j].filter.length; k++) {
                                         array.push("[\"" + props.Filters.filters[j].filter[k] + "\"]");
                                     }
                                     value = array;
                                 }

                                let temp = [
                                        'match',
                                        ['get', props.Filters.filters[j].DBname],
                                        value,
                                        true,
                                        false
                                    ];

                                console.log(temp)
                                Filterset[Filterset.length] = temp;
                            }

                            if( props.Filters.filters[j].notnull !== null){
                                isnull = false;
                                if (props.Filters.filters[j].notnull === false )
                                {
                                    if (props.Filters.filters[j].DBname ==='Начало'|| props.Filters.filters[j].DBname === "paid_value"  || props.Filters.filters[j].DBname === "auction_value"){
                                        Filterset.push([
                                            'match',
                                            ['get',props.Filters.filters[j].DBname],
                                            [0],
                                            true,
                                            false
                                        ]);
                                    }
                                    else
                                        Filterset.push([
                                            'match',
                                            ['get',props.Filters.filters[j].DBname],
                                            ['empty'],
                                            true,
                                            false
                                        ]);
                                }
                                else{
                                    if (props.Filters.filters[j].DBname ==='Начало'  || props.Filters.filters[j].DBname === "paid_value"  || props.Filters.filters[j].DBname === "auction_value"){
                                        Filterset.push([
                                            '!=',
                                            0,
                                            ['get',props.Filters.filters[j].DBname]
                                        ]);
                                    }
                                    else
                                        Filterset.push([
                                            '!=',
                                            'empty',
                                            ['get',props.Filters.filters[j].DBname]
                                        ]);
                                }

                            }
                            if (props.Filters.filters[j].type === "number" && props.Filters.filters[j].filter !== null) {
                                isnull = false;
                                var value = props.Filters.filters[j].filter[0];
                                var value2 = props.Filters.filters[j].filter[1];
                                console.log(value,value2,filtered,[">=", ['get', props.Filters.filters[j].DBname], value],["<=", ['get', props.Filters.filters[j].DBname], value2])
                                Filterset.push([">=", ['get', props.Filters.filters[j].DBname], value]);
                                Filterset.push(["<=", ['get', props.Filters.filters[j].DBname], value2]);
                            }
                            if (props.Filters.filters[j].name === 'Стратиграфия' && props.Filters.filters[j].filter !== null) {
                                isnull = false;
                                var value = props.Filters.filters[j].filter[0];
                                var value2 = props.Filters.filters[j].filter[1];
                                filtered = features;
                                filtered2 = features;
                                if (filtered.length > 0) {
                                    filtered = getUniqueFeatures(filtered, 'Начало');
                                    for (let i = 0; i < filtered.length; i++) {
                                        filtered[i] = filtered[i].properties.Начало
                                    }
                                }
                                if (filtered2.length > 0) {
                                    filtered2 = getUniqueFeatures(filtered2, 'Конец');
                                    for (let i = 0; i < filtered2.length; i++) {
                                        filtered2[i] = filtered2[i].properties.Конец
                                    }
                                }

                                Filterset.push([">=", ['get', 'Начало'], value]);
                                Filterset.push(["<=", ['get', 'Конец'], value2]);
                                map.setFilter(layerID.toString(), [
                                    "all",
                                    [">=", ['get', 'Начало'], value],
                                    ["<=", ['get', 'Конец'], value2]
                                ]);
                            }
                        }
                    }
                    if (isnull === false) {
                        console.log(Filterset)
                        //console.log(layerID,layerTextID,layerName)
                        if (layerName === "GeologicObjects") {
                            //let Filterset2 = Filterset.slice();
                            let Filterset3 = Filterset.slice();
                            //console.log(layerID,Filterset)
                            map.setFilter(layerID, null);
                            map.setFilter(layerID, Filterset);
                            //map.setFilter("0"+layerID, Filterset2)
                            map.setFilter(layerTextID, Filterset);

                        }
                        else if (layerID.includes("co2_emissions") || layerID.includes("infra_obj")) {
                            //let Filterset2 = Filterset.slice();
                            let Filterset3 = Filterset.slice();

                            map.setFilter(layerID, null);
                            map.setFilter(layerID, Filterset);
                            map.setFilter(layerID+"_icons", null);
                            map.setFilter(layerID+"_icons", Filterset);
                            //map.setFilter("0"+layerID, Filterset2);
                            map.setFilter(layerTextID, Filterset);

                        }else {
                           // console.log(layerID,layerTextID,Filterset)
                            map.setFilter(layerID, Filterset);
                            map.setFilter(layerTextID, Filterset);
                            if (layerHeat !== ""){
                                map.setFilter(layerHeat, Filterset)
                            }
                            if (layerBubble !== ""){
                                map.setFilter(layerBubble, Filterset)
                            }

                        }
                        /*
                        setTimeout(() => {
                            if (this.state.filterPropsLayer !== undefined) {
                                let features2 = map.queryRenderedFeatures({layers: [this.state.filterPropsLayer.toString()]});
                                if (features2.length > 0 && this.state.filterPropsLayer !== ""){
                                    FilterPropsList = [{}];
                                    for ( let p = 0 ; p< features2.length ; p++){
                                        let props = features2[p].properties;
                                        delete props.color0;delete props.color1;delete props.color2;delete props.color3;delete props.color4;delete props.color5;delete props.color6;delete props.color7;delete props.color8;delete props.color9;delete props.color10;delete props.Описание;
                                        FilterPropsList.push(features2[p].properties)
                                    }
                                    this.setState({filterPropsList:FilterPropsList})

                                }
                                else {
                                    this.setState({filterPropsList:FilterPropsList})
                                    props.handleAlert("info","Layer "+ layerName + " has no features to display after props.Filters applied." )
                                }
                            }
                        }, 1000);

                         */

                    } else {
                        map.setFilter(layerID, null);
                        map.setFilter(layerTextID, null)
                        if (layerID.includes("co2_emissions")) {
                            map.setFilter(layerID+"_icons", null);
                        }
                        if (layerHeat !== ""){
                            map.setFilter(layerHeat, null)
                        }
                        /*
                        if (this.state.filterPropsLayer !== "" && this.state.filterPropsLayer !== undefined) {
                            setTimeout(() => {
                                FilterPropsList = [{}];
                                let features2 = map.queryRenderedFeatures({layers: [this.state.filterPropsLayer.toString()]});
                                if (features2.length > 0 && this.state.filterPropsLayer !== ""){
                                    for ( let p = 0 ; p< features2.length ; p++){
                                        let props = features2[p].properties;
                                        delete props.color0;delete props.color1;delete props.color2;delete props.color3;delete props.color4;delete props.color5;delete props.color6;delete props.color7;delete props.color8;delete props.color9;delete props.color10;delete props.Описание;
                                        FilterPropsList.push(features2[p].properties)
                                    }
                                    this.setState({filterPropsList:FilterPropsList})

                                }

                            }, 1000);
                        }


                         */
                    }

                /*
                var user_id;
                if (Cookies.get("id") !== undefined){
                    if (only_my === false)
                        user_id = "?user_id="+  Cookies.get("id");
                    else
                        user_id = "?user_id="+  Cookies.get("id") + "&only_my=true"  ;               }
                else {
                    user_id = "?user_id=0";}
                for (let i = 0; i < props.result.length; i++){
                    if ( (props.result[i].type === "fill" || props.result[i].type === "circle" || props.result[i].type ==="line")&&(props.result[i].visibility === 'visible')){
                        let source = map.getSource(props.result[i].id);
                        if ( source !== undefined){
                            source.tiles = [props.result[i].data];
                            map.style.sourceCaches[props.result[i].id].clearTiles()
                            map.style.sourceCaches[props.result[i].id].update(map.transform)
                            map.triggerRepaint()
                            source.tiles = [props.result[i].data+user_id];
                            map.style.sourceCaches[props.result[i].id].clearTiles()
                            map.style.sourceCaches[props.result[i].id].update(map.transform)
                            map.triggerRepaint()
                        }
                    }
                }
                 */
                //this.setState({inc:this.state.inc+1,imageVisible:"none",hoveredID: null, hoveredSourceLayer: null, hoveredSourceID: null});
                //props.drawLayerOrder(map)
            }, 50);


    }, [props.map, props.Filters, props.result, props.data2, props.handleAlert, props.lassoFilter, props.lassoIds]);
    return(<></>)
}
import React, { useEffect, useState } from 'react';

const MyGeoMapGeoReference = ({ mapRef, georef_state, clear, onPointsUpdate, georef_delete,georef_delete_index, onPointsDelete }) => {
    const [points, setPoints] = useState([]);

    useEffect(() => {
        if (!mapRef.current) return;

        const handleClick = (event) => {
            if (event.originalEvent.shiftKey) {
                const { lng, lat } = event.lngLat;
                const newPoint = {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [lng, lat],
                    },
                    properties: {
                        title: points.length + 1,
                    },
                };
                const updatedPoints = [...points, newPoint];
                setPoints(updatedPoints);

                mapRef.current.getSource('georef_points').setData({
                    type: 'FeatureCollection',
                    features: updatedPoints,
                });

                onPointsUpdate(updatedPoints.map(p => p.geometry.coordinates));
            }
        };

        if (georef_state) {
            mapRef.current.on('click', handleClick);
        } else {
            mapRef.current.off('click', handleClick);
        }

        return () => {
            mapRef.current.off('click', handleClick);
        };
    }, [georef_state, points, onPointsUpdate, mapRef]);

    useEffect(() => {
        if (clear) {
            setPoints([]);
            if (mapRef.current) {
                mapRef.current.getSource('georef_points').setData({
                    type: 'FeatureCollection',
                    features: [],
                });
            }
            onPointsUpdate([]);
        }
    }, [clear, onPointsUpdate, mapRef]);
    useEffect(() => {
        console.log("delete",georef_delete,points,georef_delete_index)
        if (georef_delete) {
            let newPoints = points.splice(georef_delete_index, 1);
            setPoints(newPoints)
            if (mapRef.current) {
                mapRef.current.getSource('georef_points').setData({
                    type: 'FeatureCollection',
                    features: newPoints,
                });
            }
            onPointsDelete();
        }
    }, [georef_delete, georef_delete_index, onPointsDelete, mapRef, clear, points]);

    return null;
};

export default MyGeoMapGeoReference;

import React from 'react';
import FeatureInfosReserves from './FeatureInfosReserves'
import { Button,Label } from 'semantic-ui-react'
import point from "../help/PointIcon.png"
import pointInterface from "../help/PointInterface.png"
import pointInterfaceZoom from "../help/PointInterfaceZoom.png"
import pointGif from "../help/point.gif"
import pointInterfaceGif from "../help/pointInterface.gif"
import pointInterfaceZoomGif from "../help/pointInterfaceZoom.gif"
import { isMobile} from "react-device-detect";
class Help extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    };

    render() {

        return(
            <div className={"map-reserves"} style={{display:this.props.display,width: '100%',position: 'inherit'}}>
                <FeatureInfosReserves display={"block"} object_id={this.props.ids} multiple={true} />

            </div>
        )}}
export default Help;

import React from 'react';
import {Button, Checkbox, Icon, Input, Label} from 'semantic-ui-react'
import point from "../help/PointIcon.png"
import pointInterface from "../help/PointInterface.png"
import pointInterfaceZoom from "../help/PointInterfaceZoom.png"
import pointGif from "../help/point.gif"
import pointInterfaceGif from "../help/pointInterface.gif"
import pointInterfaceZoomGif from "../help/pointInterfaceZoom.gif"
import { isMobile} from "react-device-detect";
class Graticule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Graticule2:false,
            GraticuleSize:10,
            GraticuleSize2:1,
            GraticuleOpacity:0.9,
            GraticuleOpacity2:0.75,
            GraticuleLineWidth: 0.6,
            GraticuleLineWidth2: 0.3,
            GraticuleColor:'#050505',
            GraticuleColor2:'#1f1f1f',
        }
    };
    onSubmit = () => {
        this.props.draw_graticule(
            {
                deg: Number.parseFloat(this.state.GraticuleSize),
                opacity: Number.parseFloat(this.state.GraticuleOpacity),
                width: Number.parseFloat(this.state.GraticuleLineWidth),
                color: this.state.GraticuleColor
            },
            1
        )
        if (this.state.Graticule2)
            this.props.draw_graticule(
                {
                    deg: Number.parseFloat(this.state.GraticuleSize2),
                    opacity: Number.parseFloat(this.state.GraticuleOpacity2),
                    width: Number.parseFloat(this.state.GraticuleLineWidth2),
                    color: this.state.GraticuleColor2
                },
                2
            )
    }
    onDelete = () => {
        this.props.draw_graticule(
            {
                deg: null,
                opacity: Number.parseFloat(this.state.GraticuleOpacity),
                width: Number.parseFloat(this.state.GraticuleLineWidth),
                color: this.state.GraticuleColor
            },
            1
        )
        this.props.draw_graticule(
            {
                deg: null,
                opacity: Number.parseFloat(this.state.GraticuleOpacity2),
                width: Number.parseFloat(this.state.GraticuleLineWidth2),
                color: this.state.GraticuleColor2
            },
            2
        )
    }

    onInputChange = (value,set,type)=>{
        switch (set){
            case 1:
                switch (type){
                    case "size":
                        this.setState({GraticuleSize:value})
                        break;
                    case "opacity":
                        this.setState({GraticuleOpacity:value})
                        break;
                    case "width":
                        this.setState({GraticuleLineWidth:value})
                        break;
                    case "color":
                        this.setState({GraticuleColor:value})
                        break;
                }
                break;
            case 2:
                switch (type){
                    case "size":
                        this.setState({GraticuleSize2:value})
                        break;
                    case "opacity":
                        this.setState({GraticuleOpacity2:value})
                        break;
                    case "width":
                        this.setState({GraticuleLineWidth2:value})
                        break;
                    case "color":
                        this.setState({GraticuleColor2:value})
                        break;
                }
                break;
        }
    }
    render() {
        console.log("HELP")
        return(
            <div className="graticule">
                Нарисовать сетку координат&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Icon onClick={()=>{
                    this.props.close()
                }} corner={"top right"} name='close' />
                <br/>
                <br/>
                <div style={{display:"flex"}}><Checkbox
                    onChange={(e, data) => this.setState({Graticule2:!this.state.Graticule2})}
                    checked={this.state.Graticule2}
                /><div style={{padding:"3px 3px"}}>Вторая сетка</div> </div>
                <br/>
                <br/>
                <Label size={"tiny"}>{"Настройка первой сетки координат"}</Label>
                <br/><br/>
                <Input style={{width:"100px"}} type={"number"} size={"mini"} value={this.state.GraticuleSize} label={"Размер"} onChange={(e,data)=>{this.onInputChange(data.value,1,"size")}}/>
                <br/><br/>
                <Input style={{width:"100px"}} type={"number"} size={"mini"} value={this.state.GraticuleOpacity} label={"Прозрачность"} onChange={(e,data)=>{this.onInputChange(data.value,1,"opacity")}}/>
                <br/><br/>
                <Input style={{width:"100px"}} type={"number"} size={"mini"} value={this.state.GraticuleLineWidth} label={"Толщина Линии"} onChange={(e,data)=>{this.onInputChange(data.value,1,"width")}}/>
                <br/><br/>
                <Input style={{width:"100px"}} size={"mini"} value={this.state.GraticuleColor} label={"Цвет"} onChange={(e,data)=>{this.onInputChange(data.value,1,"color")}}/>
                <br/><br/>
                {(this.state.Graticule2)&&(
                    <>
                        <Label size={"tiny"}>{"Настройка второй сетки координат"}</Label>
                        <br/><br/>
                        <Input style={{width:"100px"}} type={"number"} size={"mini"} value={this.state.GraticuleSize2} label={"Размер"} onChange={(e,data)=>{this.onInputChange(data.value,2,"size")}}/>
                        <br/><br/>
                        <Input style={{width:"100px"}} type={"number"} size={"mini"} value={this.state.GraticuleOpacity2} label={"Прозрачность"} onChange={(e,data)=>{this.onInputChange(data.value,2,"opacity")}}/>
                        <br/><br/>
                        <Input style={{width:"100px"}} type={"number"} size={"mini"} value={this.state.GraticuleLineWidth2}  label={"Толщина Линии"} onChange={(e,data)=>{this.onInputChange(data.value,2,"width")}}/>
                        <br/><br/>
                        <Input style={{width:"100px"}} size={"mini"} value={this.state.GraticuleColor2} label={"Цвет"} onChange={(e,data)=>{this.onInputChange(data.value,2,"color")}}/>
                        <br/><br/>
                    </>
                )}
                <Button size={"tiny"} onClick={this.onSubmit}>{"Отрисовать"}</Button>
                <Button size={"tiny"} onClick={this.onDelete}>{"Убрать"}</Button>
            </div>
        )}}
export default Graticule;
import {Button, Dropdown, Icon, Label} from "semantic-ui-react";
import axios from "axios";
import {age_period, age_word, urlBackend, urlMartin} from "../VariableInitialize";
import ScrollContainer from "react-indiana-drag-scroll";
import Cookies from "js-cookie";
import DisplayImages from "./DisplayImage";
import i18n from "../i18n";
import Dialog from "@mui/material/Dialog";
import {Transition} from "../functions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import React from "react";
import GroupPermissionForObject from "./GroupPermissionForObject";
import UpdateGeoTiff from "./UpdateGeoTiff";


class FeatureInfosGeoTiffs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            geoTiffDisplay:"none",
            geotiffArray:[],
            wordBasic:age_word.reverse(),
            periodBasic:age_period,
            word:age_word.reverse(),
            period:age_period,
            age_word:age_word,
            value:[0,4600],
            age_from:null,
            age_to:null,
            geoTiffWait:false,
            open:false,
            filterDisplay:false,
            ObjectToDelete:null,
            GeotiffGroupPermDisplay:false,
            GeotiffGroupPermCssTop:0,
            GeotiffObjectID:null,
            GeotiffGroupPermArray:[{}],
            GeotiffGroupPermArrayBasic:[{}],
            editID:null,
            editName:null,
            editType:null,
            editAge_start:null,
            editAge_end:null,
            editDisplay:"none",
            editPositionTop:0,
            editPublication_url:null
        }
        this.handleFilter = this.handleFilter.bind(this);
        this.onSort = this.onSort.bind(this);
        this.onClickTo = this.onClickTo.bind(this);
        this.onClickFrom = this.onClickFrom.bind(this);
        this.onClickFilter = this.onClickFilter.bind(this);
        this.handleRedo = this.handleRedo.bind(this)
    }
    onClickFrom(e,data) {
        console.log(e,data.value.split(' ')[1])
        this.setState({age_from : data.value.split(' ')[1]})
    }
    onClickTo(e,data) {
        console.log(e,data)
        this.setState({age_to : data.value.split(' ')[1]})
    }
    onClickFilter(e,data) {
        let layer = this.props.layer;
        axios.post(urlBackend+'/postQuery', {query:{type:"get_geotiff",select_type:"get_geotiff_search_age",select_params:{
            id:this.props.id,
                    layer:layer,
                    layer_id:layer+"_id",
                    age_from:this.state.age_from,
                    age_to:this.state.age_to,
                    user_id:Cookies.get("id")}}})
            .then((res) => {
                console.log(res.data);
                //this.props.publication = res.data;
                this.setState({geoTiffDisplay:"block",geotiffArray :res.data})
            }).catch((error) => {
            console.log(error)
        });
    }
    handleFilter() {
        console.log(this.state.geoTiffDisplay,this.state.word,this.state.period)
        this.setState({filterDisplay:!this.state.filterDisplay})
    }
    handleRedo() {
        let layer = this.props.layer;
        //console.log(this.state,this.props.id,"tiff",this.props,"prop")
        axios.post(urlBackend+'/postQuery', {query:{type:"get_geotiff",select_type:"get_geotiff",select_params:{id:this.props.id,layer:layer,layer_id:layer+"_id",user_id:Cookies.get("id")}}})
            .then((res) => {
                console.log(res.data);
                //this.props.publication = res.data;
                this.setState({geoTiffDisplay:"block",geotiffArray :res.data})
            }).catch((error) => {
            console.log(error)
        });
    }
    //publication layer object_id
    onSort(sortKey){
        //Publication
        const data = this.state.geotiffArray;
        console.log(data);
        data.sort((a,b) => {

            console.log(a[sortKey],b[sortKey])
            if (this.state.publicationSort) {
                if (!a[sortKey]) {
                    // Change this values if you want to put `null` values at the end of the array
                    return +1;
                }

                if (!b[sortKey]) {
                    // Change this values if you want to put `null` values at the end of the array
                    return -1;
                }
                return a[sortKey].toString().localeCompare(b[sortKey].toString());
            }else {
                if (!a[sortKey]) {
                    // Change this values if you want to put `null` values at the end of the array
                    return +1;
                }

                if (!b[sortKey]) {
                    // Change this values if you want to put `null` values at the end of the array
                    return -1;
                }
                return b[sortKey].toString().localeCompare(a[sortKey].toString());
            }
        })
        this.setState({publication:data,publicationSort:!this.state.publicationSort})
    }

    render() {
        console.log(this.state.geoTiffDisplay,this.state.word,this.state.period)
        let icon = this.state.geoTiffDisplay === "none" ? 'plus' : 'minus';
        return(
            <div>
                <UpdateGeoTiff handleRedo={this.handleRedo} close={()=>{this.setState({editDisplay:"none"})}} editPositionTop={this.state.editPositionTop} display={this.state.editDisplay} editID={this.state.editID} type={this.state.editType} name={this.state.editName} age_from={this.state.editAge_start} age_to={this.state.editAge_end} editUrl={this.state.editPublication_url} />
                <GroupPermissionForObject csstop={this.state.GeotiffGroupPermCssTop} type={'geotiff'} arrayChange={(array)=>{this.setState({GeotiffGroupPermArray:array})}} permArrayBasic={this.state.GeotiffGroupPermArrayBasic} permArray={this.state.GeotiffGroupPermArray} id={this.state.GeotiffObjectID} close={()=>{this.setState({GeotiffGroupPermDisplay:false})}} display={this.state.GeotiffGroupPermDisplay} />
                <Label basic size={'medium'} onClick={() => {
                    if(this.state.geoTiffDisplay === "none"){
                        this.handleRedo();
                    }
                    else
                        this.setState({geoTiffDisplay:"none"})
                }}>
                    <Icon name={icon} /> {i18n.t("GeoTiff")}
                </Label>
                <div style={{display:this.state.geoTiffDisplay}}>
                    <h2 id='titlepublications'>{i18n.t("GeoTiff Table")}</h2>
                    <br/>
                    <Button circular size={'mini'} icon={{ className: 'user-filter' }} basic onClickCapture={this.handleFilter} />
                    <Button circular size={'mini'} icon={{ className: 'redo' }} basic onClickCapture={this.handleRedo} />
                    {console.log(this.state.filterDisplay)}
                    {(this.state.filterDisplay)&&(
                        <div className={'geotiff-filter'}>
                            {i18n.t("Возраст от:")}<Dropdown id={"ChronoDropDown1"} value={this.state.value_from} options={this.state.period.reverse()} onChange={this.onClickFrom} selection placeholder="Выбирите начальный период" />
                            <br/>
                            {i18n.t("Возраст до:")}<Dropdown id={"ChronoDropDown2"} value={this.state.value_to} options={this.state.period} onChange={this.onClickTo} selection placeholder="Выбирите конечный период" />
                            <br/>
                            <Button onClick={this.onClickFilter}>{i18n.t('Filter')}</Button>
                        </div>
                    )}
                    <ScrollContainer className="scroll-container">
                        <table id='publications'>
                            <tbody>
                            <tr key={"geotiff"}>
                                <th >{i18n.t("Add to map")}</th>
                                <th onClick={()=>{
                                    this.onSort("index")
                                }}>{i18n.t("Index_geotiff")}</th>
                                <th onClick={()=>{
                                    this.onSort("name")
                                }}>{i18n.t("GeoTiff Name")}</th>
                                <th onClick={()=>{
                                    this.onSort("type")
                                }}>{i18n.t("GeoTiff Type")}</th>
                                <th onClick={()=>{
                                    this.onSort("type")
                                }}>{i18n.t("Geo index start")}</th>
                                <th onClick={()=>{
                                    this.onSort("type")
                                }}>{i18n.t("Geo index end")}</th>
                                <th onClick={()=>{
                                    this.onSort("publication_name")
                                }}>{i18n.t("Publication Url")}</th>
                                <th>{i18n.t("View")}</th>
                                <th>{i18n.t("Edit")}</th>
                                <th>{i18n.t("Permission_geotiff")}</th>
                                <th>{i18n.t("Delete_geotiff")}</th>
                            </tr>
                            {/* eslint-disable-next-line array-callback-return */}
                            {this.state.geotiffArray.map(({tiffname, name, permission, type,age_start,index_start,age_end,index_end, source, doi, publication_name,publication_url, geom, user, id}, index) => {
                                let deleteObj = false;
                                let perm
                                if (permission !== undefined && permission !== null)
                                    perm = permission[0];
                                if (user !== undefined){
                                    console.log(user,Cookies.get('id'))
                                    if (user === Number(Cookies.get('id'))){
                                        deleteObj = true;
                                    }
                                }
                                let duplicate = false;
                                for ( let i = 0 ; i < this.props.result.length;i++){
                                    if ( this.props.result[i].name === name)
                                        duplicate = true;
                                }
                                let loading =false;
                                let icon = "add";
                                if(this.state.geoTiffWait){
                                    loading = true
                                    icon ="wait";
                                }
                                let tiffNameUrl;
                                if (tiffname !== null) {
                                    if (tiffname !== undefined)
                                        tiffNameUrl = tiffname;
                                    else
                                        tiffNameUrl = tiffname+".png"
                                }
                                if (tiffname !== null)
                                    return (
                                        <tr key={index}>
                                            <td>{(!duplicate)&&(<Icon disables={loading} loading={loading} onClick={async()=>{
                                                this.setState({geoTiffWait:true})
                                                axios.get(urlBackend+'/displayimage?file='+encodeURIComponent(tiffname))
                                                    .then((res) => {
                                                        let geometry = JSON.parse(geom);
                                                        let coord =[
                                                            geometry.coordinates[0][0],
                                                            geometry.coordinates[0][1],
                                                            geometry.coordinates[0][2],
                                                            geometry.coordinates[0][3]
                                                        ];
                                                        var binaryData = [];
                                                        binaryData.push(res.data);
                                                        const blob = b64toBlob(binaryData, 'image/png');
                                                        const blobUrl = URL.createObjectURL(blob);
                                                        //const blobUrl =window.URL.createObjectURL(new Blob(binaryData, {type: "image/png"}));
                                                        console.log(blobUrl,JSON.parse(geom))
                                                        index++;
                                                        // LayerOptions UserOptions userdataname presetdata dataname data2
                                                        this.props.result.unshift({name:name,data:blobUrl,color:"#FF00FF",id:this.props.data.length+1,beforeid:this.props.data.length+2,visibility:"visible",sourcelayer:binaryData,opacity:0.8,type:'image',text:false,border:coord,hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                                                        this.props.LayerOptions.unshift({name:name,data:'none',data2:"none",id:this.props.data.length+1});
                                                        this.props.UserOptions.unshift({name:name,data:'none',data2:"none",id:20+this.props.data.length+1});
                                                        this.props.userdataname.unshift({index:2+this.props.userdataname.length,name:name,type:"ImageLayer"});
                                                        this.props.data.unshift({name:name,data:blobUrl,color:"#FF00FF",id:this.props.data.length+1,beforeid:this.props.data.length+2,visibility:"visible",sourcelayer:binaryData,opacity:0.8,type:'image',text:false,border:coord,hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                                                        this.props.presetdata.unshift({name:name,data:blobUrl,color:"#FF00FF",id:this.props.data.length+1,beforeid:this.props.data.length+2,visibility:"visible",sourcelayer:binaryData,opacity:0.8,type:'image',text:false,border:coord,hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                                                        this.props.dataname.unshift({index:this.props.dataname.length,name:name,type:"ImageLayer"});
                                                        this.props.data2.unshift({name:name+"Text",data:urlMartin+'/public.basinscenter/{z}/{x}/{y}.pbf',color:"#00218B",id:this.props.data2[this.props.data2.length-1].id+"tif",beforeid:false,visibility:"none",sourcelayer:'public.basinscenter',opacity:0.8,type:'symbol',layoutname:'OriginalName'})
                                                        let layer = this.props.layer;
                                                        axios.post(urlBackend+'/postQuery', {query:{type:"get_geotiff",select_type:"get_geotiff",select_params:{id:this.props.id,layer:layer,layer_id:layer+"_id",user_id:Cookies.get('id')}}})
                                                            .then((res) => {
                                                                console.log(res.data);
                                                                //this.props.publication = res.data;
                                                                this.setState({geoTiffDisplay:"block",geotiffArray :res.data,geoTiffWait:false})
                                                                this.props.layerupdate();
                                                            }).catch((error) => {
                                                            console.log(error)
                                                            this.setState({geoTiffWait:false})
                                                            this.props.layerupdate();
                                                        });
                                                    }).catch((error) => {
                                                    console.log(error)
                                                });
                                                const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
                                                    const byteCharacters = atob(b64Data);
                                                    const byteArrays = [];

                                                    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                                                        const slice = byteCharacters.slice(offset, offset + sliceSize);

                                                        const byteNumbers = new Array(slice.length);
                                                        for (let i = 0; i < slice.length; i++) {
                                                            byteNumbers[i] = slice.charCodeAt(i);
                                                        }

                                                        const byteArray = new Uint8Array(byteNumbers);
                                                        byteArrays.push(byteArray);
                                                    }

                                                    const blob = new Blob(byteArrays, {type: contentType});
                                                    return blob;
                                                };
                                            }} name={icon} />)}</td>
                                            <td>{index}</td>
                                            <td>{name}</td>
                                            <td>{type}</td>
                                            <td>{age_start}</td>
                                            <td>{age_end}</td>
                                            <td><a href={publication_url}>{publication_name}</a></td>
                                            <td onClick={() => {
                                                let display;
                                                if (this.state.imageDisplay === "none")
                                                    display = "block"
                                                else
                                                    display = "none"
                                                this.setState({imageDisplay:display})
                                            }} ><DisplayImages display={"block"} url={urlBackend+'/displaypreview?file='+encodeURIComponent(tiffNameUrl)}/></td>
                                            <td>{(perm === 'Изменение' || deleteObj) && (
                                                <div>
                                                    <Icon name='edit' onClick={(e)=>{
                                                        console.log("ondelete",id,name,type,age_start,age_end,age_word,publication_url)
                                                        this.setState({editID:id,
                                                            editDisplay:"block",
                                                            editName:name,
                                                            editType:type,
                                                            editAge_start:age_start,
                                                            editAge_end:age_end,
                                                            editPositionTop:e.pageY-200,
                                                            editPublication_url:publication_url});
                                                    }} />
                                                </div>
                                            )}
                                            </td>
                                            <td>
                                                {(perm === 'Изменение' || deleteObj) && (
                                                    <>
                                                        <Icon name='group' onClick={(e)=> {
                                                            e.persist()
                                                            console.log(id)
                                                            let PermQuary = {type:"get_Groups_Permission",select_type:"get_No_Object_Groups_Permission_Geotiff",select_params:{object_id:id,user_id:Cookies.get("id"),type:'geotiff_map'}}
                                                            axios.post(urlBackend+'/postQuery', {query: PermQuary})
                                                                .then((res) => {
                                                                    console.log(res.data,e.pageY,e)
                                                                    this.setState({GeotiffGroupPermDisplay:true,GeotiffGroupPermCssTop:e.pageY,GeotiffObjectID:id,GeotiffGroupPermArray:res.data[0].get_no_geo_object_permission,GeotiffGroupPermArrayBasic:JSON.parse(JSON.stringify(res.data[0].get_no_geo_object_permission))})
                                                                })
                                                                .catch((error)=>{console.log(error)})
                                                        }} />
                                                    </>
                                                )}
                                            </td>
                                            <td>{(perm === 'Изменение' || deleteObj) && (
                                                <div>
                                                    <Icon name='delete' onClick={()=>{
                                                        console.log("ondelete",id)
                                                        this.setState({open:true,ObjectToDelete:id});
                                                    }} />
                                                    <Dialog
                                                        classes={{ root: 'MenuItem'}}
                                                        open={this.state.open}
                                                        TransitionComponent={Transition}
                                                        keepMounted
                                                        onClose={this.handleCancelDelete}
                                                        aria-labelledby="alert-dialog-slide-title"
                                                        aria-describedby="alert-dialog-slide-description"
                                                    >
                                                        <DialogTitle id="alert-dialog-slide-title">{"Delete"}</DialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText id="alert-dialog-slide-description">
                                                                Confirm Delete
                                                            </DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={this.handleCancelDelete} color="primary">
                                                                Disagree
                                                            </Button>
                                                            <Button onClick={()=> {
                                                                console.log("click" ,this.state.ObjectToDelete);
                                                                axios.post(urlBackend+'/layerid', {query:"DELETE FROM geotiff_map WHERE f_attachment_id = " + this.state.ObjectToDelete})
                                                                    .then((res) => {
                                                                        let layer = this.props.layer;
                                                                        axios.post(urlBackend+'/layerid', {query:"DELETE FROM attachments WHERE f_attachment_id = " + this.state.ObjectToDelete })
                                                                            .then((res) => {
                                                                                axios.post(urlBackend+'/postQuery', {query:{type:"get_geotiff",select_type:"get_geotiff",select_params:{id:this.props.id,layer:layer,layer_id:layer+"_id",user_id:Cookies.get("id")}}})
                                                                                    .then((res) => {
                                                                                        console.log(res.data);
                                                                                        //this.props.publication = res.data;
                                                                                        this.setState({geoTiffDisplay:"block",geotiffArray :res.data,open:false,ObjectToDelete:null})
                                                                                    }).catch((error) => {
                                                                                    console.log(error)
                                                                                });
                                                                            }).catch((error) => {
                                                                            console.log(error)
                                                                        });
                                                                    }).catch((error) => {
                                                                    console.log(error)
                                                                });
                                                            }} color="primary">
                                                                Agree
                                                            </Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                </div>
                                            )}
                                            </td>
                                        </tr>
                                    )
                            })}
                            </tbody>
                        </table>
                    </ScrollContainer>
                </div>
            </div>
        )
    }
}
export default FeatureInfosGeoTiffs;
import {useEffect, useRef, useState} from "react";

export default function MyGeoMapSource(props) {
    const [tiles,setTiles] = useState(undefined)
    const [loaded,setLoaded] =useState(false)
    useEffect(() => {
        //console.log(props.map,props.type,props.id,props.tileSize,props.promoteId,props.maxTileCacheSize,props.minzoom,props.maxzoom,props.tiles,props.data)

        if (!props.map || props.map.current === null )
            return
        props.map.current.once('styledata',()=>{
            if (!props.map.current.getSource(props.id)) {
                if (props.type === 'geojson'){
                    props.map.current.addSource(props.id, {
                        'type': props.type,
                        'data': props.data
                    });
                }else if(props.type === 'raster'){
                    console.log(props.id,props.type,props.tiles,props.tileSize)
                    props.map.current.addSource(props.id, {
                        'type': props.type,
                        'tiles': props.tiles,
                        'tileSize':(props.tileSize ? props.tileSize : 256)
                    });
                }else if(props.type === 'image') {
                    props.map.current.addSource(props.id, {
                        'type': props.type,
                        'url': props.url,
                        'coordinates':props.coordinates
                    });
                }else if(props.type === 'vector'){
                    props.map.current.addSource(props.id, {
                        'type': props.type,
                        'tiles': props.tiles,
                        'tileSize': 512,
                        'maxzoom':props.maxzoom,
                        'minzoom':props.minzoom,
                        'maxTileCacheSize':props.maxTileCacheSize,
                        'promoteId':props.promoteId
                    });
                }
            }
            setLoaded(true)
        })
        

    }, [props.visibility, props.map, props.type, props.id, props.tileSize, props.promoteId, props.maxTileCacheSize, props.minzoom, props.maxzoom, props.tiles, props.data, props.coordinates]);
    useEffect(() => {
        //console.log('change tiles',props.map,props.map.current,loaded)
        if (!props.map || props.map.current === null || !loaded)
            return
        //console.log("has map",props.id,props.tiles,tiles,props.type === 'vector' , tiles )
        if ( props.type === 'vector' && tiles && props.tiles[0] !== tiles[0] && props.map.current.getSource(props.id)){
            console.log("change tiles" ,props.id, tiles , props.tiles , props.tiles !== tiles)
            props.map.current.getSource(props.id).setTiles(props.tiles);
            setTiles(props.tiles)
        }else if(!tiles) {
            setTiles(props.tiles)
        }
    }, [loaded, props.id, props.map, props.tiles, props.type, tiles]);
    return(<></>)
}
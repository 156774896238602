import React, {Component} from "react";
import Cookies from "js-cookie";
import axios from "axios";
import mapboxgl from "mapbox-gl";
import {Button, Dropdown, Input} from "semantic-ui-react";
import GroupPermissionForObject from "./GroupPermissionForObject";
const config = require('../config');
const hostBackend = config.HOSTBackend;
const portBackend = config.PORTBackend;
const protocolBackend = config.ProtocolBackend;
const prefixBackend = config.PrefixBackend;
var urlBackend;
if (portBackend === "" || portBackend === undefined){
    urlBackend=protocolBackend+'://'+hostBackend+prefixBackend;
}
else{
    urlBackend=protocolBackend+'://'+hostBackend+':'+portBackend+prefixBackend;
}
class UpdateGeometry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: '',
            selected2: '',
            selected3: '',
            selected4: '',
            selected5: '',
            layer: [],
            new_qc_id:'',
            data:[{index:0,name:"Basins"},{index:1,name:"GeologicMap"},{index:2,name:"TectonicMap"},{index:3,name:"SubBasins"},{index:4,name:"Regions"},{index:5,name:"Licenses"},{index:6,name:"Fields"},{index:7,name:"GeologicObjects"},{index:8,name:"Wells"},{index:9,name:"ProtectedAreas"},{index:10,name:"Seismics"}],
            columns:[],
            word:[],
            word2:[],
            PermissionDisplay:"none",
            groupPermArray:[],
            groupPermArrayBasic:[],
            display:this.props.display,
            source: this.props.source,
            editdisplay:'none',
            basinsdisplay:'none',
            fielddisplay:'none',
            subbasinsdisplay:'none',
            licensesdisplay:'none',
            geologicmapdisplay:'none',
            tectonicmapdisplay:'none',
            regionsdisplay:'none',
            geoobjectsdisplay:'none',
            wellsdisplay:'none',
            seismicssdisplay:'none',
            typedata : [{name:"Basins",data: [{name:"Name",type:"String",table:"basins",wordtable:"basin_class",value:''}]}
                ,{name:"Fileds",data:[{name:"Name",type:"String",table:"fields",wordtable:"basin_class",value:''}]}
                ,{name:"SubBasins",data:[{name:"Name",type:"String",table:"subbasins",wordtable:"basin_class",value:''}]}
                ,{name:"Licenses",data:[{name:"Name",type:"String",table:"licenses",wordtable:"basin_class",value:''}]}
                ,{name:"GeologicObjects",data:[{name:"Name",type:"String",table:"geo_objects",wordtable:"basin_class",value:''}]}
                ,{name:"GeologicMap",data:[{name:"Name",type:"String",table:"geologicmap",wordtable:"basin_class",value:''}]}
                ,{name:"TectonicMap",data:[{name:"Name",type:"String",table:"tectonicmap",wordtable:"basin_class",value:''}]}
                ,{name:"Regions",data:[{name:"Name",type:"String",table:"regions",wordtable:"basin_class",value:''}]}
                ,{name:"Wells",data:[{name:"Name",type:"String",table:"wells",wordtable:"basin_class",value:''}]}
                ,{name:"Seismics",data:[{name:"Name",type:"String",table:"seismics",wordtable:"basin_class",value:''}]}
            ],
        };
        this.onSelect = this.onSelect.bind(this);
        this.onSelect2 = this.onSelect2.bind(this);
        this.onSelect3 = this.onSelect3.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.input2 = this.input2.bind(this);
        this.input3 = this.input3.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    handleClick() {
        let name;
        if ( this.state.source === "basins")
            name = this.state.typedata[0].data[0].value;
        else
            name = this.state.typedata[1].data[0].value;
        if (name === undefined)
            name = this.props.name;
        let qc_id = this.props.qc_id;
        if ( this.state.source === '')
            this.state.source = this.props.source;
        if (Cookies.get("id") !== undefined) {
            if (this.props.myObj) {
                axios.post(urlBackend+'/layerid', {query: "UPDATE " + this.state.source + " SET (original_name,wkb_geometry,user_id,view_state) = ('" + name + "', ST_SetSRID(ST_GeomFromText('" + this.props.coord + "'),4326) , " + Cookies.get("id") + " , 'USER' ) WHERE user_id = " + Cookies.get("id") + " AND " + this.state.source + "_id = " + qc_id + " ;"})
                    .then((res) => {
                        //console.log(res);
                        this.props.reset();
                        this.props.layerchange();
                    })
                    .then((res) => {
                        //console.log(res)
                    })
                    .catch((error) => {
                        console.log(error)
                    });
            } else if (Number.isInteger(qc_id)){
                axios.post(urlBackend+'/layerid', {query: "select view_state from " + this.state.source + " where " + this.state.source + "_id = " + qc_id })
                    .then((res) => {
                        let view = res.data[0].view_state
                        if (Number.isInteger(qc_id) && view === null) {
                            axios.post(urlBackend+'/layerid', {query: "INSERT INTO " + this.state.source + " (original_name,wkb_geometry,user_id,view_state,parent_qc_id) VALUES ('" + name + "', ST_SetSRID(ST_GeomFromText('" + this.props.coord + "'),4326) , " + Cookies.get("id") + " , NULL, "+qc_id+" ) RETURNING " + this.state.source + "_id as qc_id;"})
                                .then((res) => {
                                    let new_qc_id =res.data[0].qc_id;
                                    this.props.reset();
                                    this.setPermissiona(new_qc_id);
                                    //console.log(new_qc_id);
                                    axios.post(urlBackend+'/layerid', {query: "UPDATE " + this.state.source + " SET (original_name,view_state) = ('" + name + "' , 'ARCHIVE' ) WHERE " + this.state.source + "_id = " + qc_id + " ;"})
                                        .then((res) => {
                                            if (this.state.source === "basins") {
                                                console.log("UPDATE " + this.state.source + "_infos SET (basin_name,author_id,view_state,"+ this.state.source +"_id) = ('" + name + "' , " + Cookies.get("id") + " , NULL, "+new_qc_id+" ) WHERE " + this.state.source + "_infos_id = (select " + this.state.source + "_infos_id from " + this.state.source + "_infos where " + this.state.source + "_id = " + qc_id + " Order by update_date desc limit 1);")
                                                axios.post(urlBackend + '/layerid', {query: "UPDATE " + this.state.source + "_infos SET (basin_name,author_id,view_state,"+ this.state.source +"_id) = ('" + name + "' , " + Cookies.get("id") + " , NULL, "+new_qc_id+" ) WHERE " + this.state.source + "_infos_id = (select " + this.state.source + "_infos_id from " + this.state.source + "_infos where " + this.state.source + "_id = " + qc_id + " Order by update_date desc limit 1);"})
                                                    .then((res) => {
                                                        console.log(res)
                                                        this.props.layerchange();
                                                        this.props.reset();
                                                    })
                                                    .catch((error) => {
                                                        console.log(error)
                                                    });
                                            }
                                            if (this.state.source === "geo_objects") {
                                                axios.post(urlBackend + '/layerid', {query: "UPDATE " + this.state.source + "_infos SET (object_name,author_id,view_state,"+ this.state.source +"_id) = ('" + name + "' , " + Cookies.get("id") + " , NULL, "+new_qc_id+" ) WHERE " + this.state.source + "_infos_id = (select " + this.state.source + "_infos_id from " + this.state.source + "_infos where " + this.state.source + "_id = " + qc_id + " Order by update_date desc limit 1);"})
                                                    .then((res) => {
                                                        console.log(res)
                                                        this.props.layerchange();
                                                        this.props.reset();
                                                    })
                                                    .catch((error) => {
                                                        console.log(error)
                                                    });
                                            }
                                            else if (this.state.source === "wells") {
                                                axios.post(urlBackend + '/layerid', {query: "UPDATE " + this.state.source + "_infos SET (well_name,author_id,view_state,"+ this.state.source +"_id) = ('" + name + "' , " + Cookies.get("id") + " , NULL, "+new_qc_id+" ) WHERE " + this.state.source + "_infos_id = (select " + this.state.source + "_infos_id from " + this.state.source + "_infos where " + this.state.source + "_id = " + qc_id + " Order by update_date desc limit 1);"})
                                                    .then((res) => {
                                                        //console.log(res)
                                                        this.props.layerchange();
                                                        this.props.reset();
                                                    })
                                                    .catch((error) => {
                                                        console.log(error)
                                                    });
                                            }
                                            else if (this.state.source === "seismics") {
                                                axios.post(urlBackend + '/layerid', {query: "UPDATE " + this.state.source + "_infos SET (s_lin_name,author_id,view_state,"+ this.state.source +"_id) = ('" + name + "' , " + Cookies.get("id") + " , NULL, "+new_qc_id+" ) WHERE " + this.state.source + "_infos_id = (select " + this.state.source + "_infos_id from " + this.state.source + "_infos where " + this.state.source + "_id = " + qc_id + " Order by update_date desc limit 1);"})
                                                    .then((res) => {
                                                        //console.log(res)
                                                        this.props.layerchange();
                                                        this.props.reset();
                                                    })
                                                    .catch((error) => {
                                                        console.log(error)
                                                    });
                                            }
                                            else if (this.state.source === "subbasins") {
                                                axios.post(urlBackend + '/layerid', {query: "UPDATE " + this.state.source + "_infos SET (subbasin_name,author_id,view_state,"+ this.state.source +"_id) = ('" + name + "' , " + Cookies.get("id") + " , NULL, "+new_qc_id+" ) WHERE " + this.state.source + "_infos_id = (select " + this.state.source + "_infos_id from " + this.state.source + "_infos where " + this.state.source + "_id = " + qc_id + " Order by update_date desc limit 1);"})
                                                    .then((res) => {
                                                        //console.log(res)
                                                        this.props.layerchange();
                                                        this.props.reset();
                                                    })
                                                    .catch((error) => {
                                                        console.log(error)
                                                    });
                                            }
                                            else{
                                                axios.post(urlBackend + '/layerid', {query: "UPDATE " + this.state.source + "_infos SET ("+ this.state.source +"_name,author_id,view_state,"+ this.state.source +"_id) = ('" + name + "' , " + Cookies.get("id") + " , NULL, "+new_qc_id+" ) WHERE " + this.state.source + "_id = " + qc_id + " AND view_state is NULL ;"})
                                                    .then((res) => {
                                                        console.log(res)
                                                        this.props.reset();
                                                        this.props.layerchange();
                                                    })
                                                    .catch((error) => {
                                                        console.log(error)
                                                    });
                                            }
                                        })
                                        .catch((error) => {
                                            console.log(error)
                                        });
                                })
                                .catch((error) => {
                                    console.log(error)
                                });
                        }
                    })
                    .catch((error) => {
                        console.log(error,"select view_state from " + this.state.source + " where " + this.state.source + "_id = " + qc_id,)
                    });
            }
            else {
                //console.log("INSERT INTO " + this.state.source + " (original_name,wkb_geometry,user_id,view_state) VALUES ('" + name + "', ST_SetSRID(ST_GeomFromText('" + this.props.coord + "'),4326) , " + Cookies.get("id") + " , 'USER' ) RETURNING " + this.state.source + "_id as qc_id;")
                axios.post(urlBackend + '/layerid', {query: "INSERT INTO " + this.state.source + " (original_name,wkb_geometry,user_id,view_state) VALUES ('" + name + "', ST_SetSRID(ST_GeomFromText('" + this.props.coord + "'),4326) , " + Cookies.get("id") + " , 'USER' ) RETURNING " + this.state.source + "_id as qc_id;"})
                    .then((res) => {
                        console.log(this.state.source);
                        this.setState({new_qc_id: res.data[0].qc_id, source: this.state.source});
                        this.setPermissiona(res.data[0].qc_id);
                        this.props.reset();
                        if (this.state.source === "basins") {
                            // console.log("INSERT INTO \" + this.state.source + \"_infos (basin_name,author_id,basins_id) VALUES ('\" + name + \"', \" + Cookies.get(\"id\") + \" , \" + this.state.new_qc_id + \");")
                            return axios.post(urlBackend + '/layerid', {query: "INSERT INTO " + this.state.source + "_infos (basin_name,author_id,basins_id) VALUES ('" + name + "', " + Cookies.get("id") + " , " + this.state.new_qc_id + ");"})
                                .then((res) => {
                                    //console.log(res)
                                    this.props.layerchange();
                                })
                        } else if (this.state.source === "fields"){
                            //console.log("Fileds")
                            return axios.post(urlBackend + '/layerid', {query: "INSERT INTO " + this.state.source + "_infos (fields_name,author_id,fields_id,data_availability_id,confidence_degree_id,data_source_id) VALUES ('" + name + "', " + Cookies.get("id") + " , " + this.state.new_qc_id + ",0,0,0);"})

                        }
                        else if (this.state.source === "geo_objects"){
                            //console.log("INSERT INTO " + this.state.source + "_infos (" + this.state.source + "_name,author_id," + this.state.source + "_id) VALUES ('" + name + "', " + Cookies.get("id") + " , " + this.state.new_qc_id + ");",)
                            return axios.post(urlBackend + '/layerid', {query: "INSERT INTO " + this.state.source + "_infos (object_name,author_id," + this.state.source + "_id) VALUES ('" + name + "', " + Cookies.get("id") + " , " + this.state.new_qc_id + ");"})
                                .then(() => {
                                    this.props.layerchange();
                                })
                        }
                        else if (this.state.source === "subbasins") {
                            //console.log("INSERT INTO " + this.state.source + "_infos (" + this.state.source + "_name,author_id," + this.state.source + "_id) VALUES ('" + name + "', " + Cookies.get("id") + " , " + this.state.new_qc_id + ");",)
                            return axios.post(urlBackend + '/layerid', {query: "INSERT INTO " + this.state.source + "_infos (subbasin_name,author_id," + this.state.source + "_id,data_availability_id,confidence_degree_id,data_source_id) VALUES ('" + name + "', " + Cookies.get("id") + " , " + this.state.new_qc_id + ",0,0,0);"})
                                .then(() => {
                                    this.props.layerchange();
                                })
                        }
                        else if (this.state.source === "seismics") {
                            //console.log("INSERT INTO " + this.state.source + "_infos (" + this.state.source + "_name,author_id," + this.state.source + "_id) VALUES ('" + name + "', " + Cookies.get("id") + " , " + this.state.new_qc_id + ");",)
                            return axios.post(urlBackend + '/layerid', {query: "INSERT INTO " + this.state.source + "_infos (s_lin_name,author_id," + this.state.source + "_id,data_availability_id,confidence_degree_id,data_source_id) VALUES ('" + name + "', " + Cookies.get("id") + " , " + this.state.new_qc_id + ",0,0,0);"})
                                .then(() => {
                                    this.props.layerchange();
                                })
                        }
                        else if (this.state.source === "wells") {
                            //console.log("INSERT INTO " + this.state.source + "_infos (" + this.state.source + "_name,author_id," + this.state.source + "_id) VALUES ('" + name + "', " + Cookies.get("id") + " , " + this.state.new_qc_id + ");",)
                            return axios.post(urlBackend + '/layerid', {query: "INSERT INTO " + this.state.source + "_infos (well_name,author_id," + this.state.source + "_id,data_availability_id,confidence_degree_id,data_source_id) VALUES ('" + name + "', " + Cookies.get("id") + " , " + this.state.new_qc_id + ",0,0,0);"})
                                .then(() => {
                                    this.props.layerchange();
                                })
                        }
                        else {
                            //console.log("INSERT INTO " + this.state.source + "_infos (" + this.state.source + "_name,author_id," + this.state.source + "_id) VALUES ('" + name + "', " + Cookies.get("id") + " , " + this.state.new_qc_id + ");",)
                            return axios.post(urlBackend + '/layerid', {query: "INSERT INTO " + this.state.source + "_infos (" + this.state.source + "_name,author_id," + this.state.source + "_id,data_availability_id,confidence_degree_id,data_source_id) VALUES ('" + name + "', " + Cookies.get("id") + " , " + this.state.new_qc_id + ",0,0,0);"})
                                .then(() => {
                                    this.props.layerchange();
                                })
                        }

                    })
                    .then((res) => {
                        //console.log(res);
                        this.props.reset();
                        this.props.layerchange();
                        mapboxgl.clearStorage();
                    })
                    .catch((error) => {
                        console.log(error)
                    });
            }
        }
    }
    inputChange(e, { name, value }) {
        if (value.length > 0){
            axios.post(urlBackend+'/layerid', {query:"Select object_index as index from chrono_strat where object_index LIKE '"+value+"%'"})
                .then((res) => {
                    this.setState({word2:res.data})
                })
                .catch((error) => {
                    console.log(error)
                });

        }
        //console.log(value)
    }
    setPermissiona(id) {
        for (let i=0; i< this.state.groupPermArrayBasic.length;i++){
            console.log(this.state.groupPermArrayBasic[i] === null , i, typeof this.state.groupPermArrayBasic[i].permissions,id,this.state.groupPermArray[i].id)
            let permCode = null;
            if ( this.state.groupPermArray[i].permissions === 'read')
                permCode = 1;
            if ( this.state.groupPermArray[i].permissions === 'change')
                permCode = 2;
            if ( this.state.groupPermArrayBasic[i].permissions === null && this.state.groupPermArray[i].permissions !== null){
                //insert
                console.log("insert from NULL to " + this.state.groupPermArray[i].permissions)
                let PermQuary = {type:"insert_Groups_Permission",select_type:"insert_Groups_Permission",select_params:{object_id:id,group_id:this.state.groupPermArray[i].id,permission:permCode, layer_id:this.state.source}}
                axios.post(urlBackend+'/postQuery', {query: PermQuary})
                    .then((res) => {
                        console.log(res)
                    })
                    .catch((error)=>{console.log(error)})
            }else if ( this.state.groupPermArrayBasic[i].permissions !== null && this.state.groupPermArray[i].permissions === null){
                //delete
                console.log("delete from " + this.state.groupPermArrayBasic[i].permissions)
                let PermQuary = {type:"delete_Groups_Permission",select_type:"delete_Groups_Permission",select_params:{object_id:id,group_id:this.state.groupPermArray[i].id}}
                axios.post(urlBackend+'/postQuery', {query: PermQuary})
                    .then((res) => {
                        console.log(res)
                    })
                    .catch((error)=>{console.log(error)})
            }else if ( this.state.groupPermArrayBasic[i].permissions !== null && this.state.groupPermArray[i].permissions !== null && this.state.groupPermArrayBasic[i].permissions !== this.state.groupPermArray[i].permissions){
                //update
                console.log("update from " +this.state.groupPermArrayBasic[i].permissions + " to " + this.state.groupPermArray[i].permissions)
                let PermQuary = {type:"update_Groups_Permission",select_type:"update_Groups_Permission",select_params:{object_id:id,group_id:this.state.groupPermArray[i].id,permission:permCode, layer_id:this.state.source}}
                axios.post(urlBackend+'/postQuery', {query: PermQuary})
                    .then((res) => {
                        console.log(res)
                    })
                    .catch((error)=>{console.log(error)})
            }
        }
    }
    onSelect(event,{value}) {
        //console.log(value)
        let PermQuary = {type:"get_Groups_Permission",select_type:"get_Groups_Permission_Without_Object",select_params:{user_id:Cookies.get("id")}}
        axios.post(urlBackend+'/postQuery', {query: PermQuary})
            .then((res) => {
                console.log(res)
                this.setState({PermissionDisplay:"block",groupPermArray:res.data[0].get_permission_groups,groupPermArrayBasic:JSON.parse(JSON.stringify(res.data[0].get_permission_groups))})
            })
            .catch((error)=>{console.log(error)})
        if (value === "Basins"){

            this.setState({source:value.toLowerCase(),selected: value,geoobjectsdisplay:'none',editdisplay:'block',fielddisplay:'none',subbasinsdisplay:'none', licensesdisplay:'none', geologicmapdisplay:'none', tectonicmapdisplay:'none', regionsdisplay:'none',wellsdisplay:"none",seismicssdisplay:"none"});
        }
        else if (value ==="Fields"){
            this.setState({source:value.toLowerCase(),selected: value,geoobjectsdisplay:'none',fielddisplay:'block',editdisplay:'none',subbasinsdisplay:'none', licensesdisplay:'none', geologicmapdisplay:'none', tectonicmapdisplay:'none', regionsdisplay:'none',wellsdisplay:"none",seismicssdisplay:"none"});
        }
        else if (value ==="SubBasins"){
            this.setState({source:value.toLowerCase(),selected: value,geoobjectsdisplay:'none',fielddisplay:'none',editdisplay:'none',subbasinsdisplay:'block', licensesdisplay:'none', geologicmapdisplay:'none', tectonicmapdisplay:'none', regionsdisplay:'none',wellsdisplay:"none",seismicssdisplay:"none"});
        }else if (value ==="GeologicMap"){
            this.setState({source:value.toLowerCase(),selected: value,geoobjectsdisplay:'none',fielddisplay:'none',editdisplay:'none',subbasinsdisplay:'none', licensesdisplay:'none', geologicmapdisplay:'block', tectonicmapdisplay:'none', regionsdisplay:'none',wellsdisplay:"none",seismicssdisplay:"none"});
        }else if (value ==="Licenses"){
            this.setState({source:value.toLowerCase(),selected: value,geoobjectsdisplay:'none',fielddisplay:'none',editdisplay:'none',subbasinsdisplay:'none', licensesdisplay:'block', geologicmapdisplay:'none', tectonicmapdisplay:'none', regionsdisplay:'none',wellsdisplay:"none",seismicssdisplay:"none"});
        }else if (value ==="TectonicMap"){
            this.setState({source:value.toLowerCase(),selected: value,geoobjectsdisplay:'none',fielddisplay:'none',editdisplay:'none',subbasinsdisplay:'none', licensesdisplay:'none', geologicmapdisplay:'none', tectonicmapdisplay:'block', regionsdisplay:'none',wellsdisplay:"none",seismicssdisplay:"none"});
        }else if (value ==="Regions"){
            this.setState({source:value.toLowerCase(),selected: value,geoobjectsdisplay:'none',fielddisplay:'none',editdisplay:'none',subbasinsdisplay:'none', licensesdisplay:'none', geologicmapdisplay:'none', tectonicmapdisplay:'none', regionsdisplay:'block',wellsdisplay:"none",seismicssdisplay:"none"});
        }
        else if (value ==="Wells"){
            this.setState({source:value.toLowerCase(),selected: value,geoobjectsdisplay:'none',fielddisplay:'none',editdisplay:'none',subbasinsdisplay:'none', licensesdisplay:'none', geologicmapdisplay:'none', tectonicmapdisplay:'none', regionsdisplay:'none',wellsdisplay:"block",seismicssdisplay:"none"});
        }
        else if (value ==="Seismics"){
            this.setState({source:value.toLowerCase(),selected: value,geoobjectsdisplay:'none',fielddisplay:'none',editdisplay:'none',subbasinsdisplay:'none', licensesdisplay:'none', geologicmapdisplay:'none', tectonicmapdisplay:'none', regionsdisplay:'none',wellsdisplay:"none",seismicssdisplay:"block"});
        }
        else if (value ==="GeologicObjects"){
            //console.log("Geo_Obj")
            this.setState({source:"geo_objects",selected: value,geoobjectsdisplay:'block',fielddisplay:'none',editdisplay:'none',subbasinsdisplay:'none', licensesdisplay:'none', geologicmapdisplay:'none', tectonicmapdisplay:'none', regionsdisplay:'none',wellsdisplay:"none",seismicssdisplay:"none"});
        }
        if (this.state.source === "" && value !== "GeologicObjects") {

            this.setState({source:value.toLowerCase()})
        }
    }
    onSelect2(evenct,{value}){
        let res = this.state.typedata;
        res[0].data[1].value = value;
        this.setState({typedata: res,selected3:value});
    }
    onSelect3(evenct,{value}){
        let result = this.state.typedata;
        axios.post(urlBackend+'/layerid', {query:'Select f_basin_class_id as id from basin_class where f_basin_class_name = \''+value+'\' '})
            .then((res) => {
                result[0].data[3].value = res.data[0].id;
                this.setState({typedata: result,selected5:value});
            }).catch((error) => {
            console.log(error)
        });

    }
    handleCancel(){
        this.props.reset();
    }
    input2 (e, { name, value }) {
        let res = this.state.typedata;
        if (this.state.source === "basins")
            res[0].data[0].value = value;
        else
            res[1].data[0].value = value;
        // console.log(res);
        this.setState({typedata: res});
    }
    input3 (e, { name, value })  {
        let res = this.state.typedata;
        res[0].data[2].value = value;
        // console.log(res);
        this.setState({typedata: res});
    }
    componentDidUpdate(prevProps) {
        // Популярный пример (не забудьте сравнить пропсы):

        if (this.props.coord !== prevProps.coord) {
            if (this.props.coord.includes("POINT")){
                this.setState({
                    data:[{index:7,name:"GeologicObjects"},{index:8,name:"Wells"}]});
            }
            if(this.props.coord.includes("LINESTRING")){
                this.setState({
                    data:[{index:10,name:"Seismics"}]});
            }
            if(this.props.coord.includes("POLYGON")){
                this.setState({
                    data:[{index:0,name:"Basins"},{index:1,name:"GeologicMap"},{index:2,name:"TectonicMap"},{index:3,name:"SubBasins"},{index:4,name:"Regions"},{index:5,name:"Licenses"},{index:6,name:"Fields"},{index:9,name:"ProtectedAreas"}]});
            }

        }
    }
    render() {
        let res = this.state.typedata;
        let exist = false;
        console.log(this.props.coord,this.props.coord.includes("POINT"),this.props.coord.includes("LINESTRING"),this.props.coord.includes("POLYGON"));
        if ( this.props.qc_id !== null && this.props.source !== ""){
            if (this.state.source === "basins")
                res[0].data[0].value = this.props.name;
            else
                res[1].data[0].value = this.props.name;
            exist = true
            console.log(this.props,this.state.source)
        }
        //console.log(exist)
        return (
            <div className="redact" style={{display:this.props.display}}>
                {exist && (<span>
                    <label>Object from : {this.props.source}
                    </label>
                    <br/>
                </span>)}
                {!exist && (<span>
                <label>Слой:</label>
                <Dropdown options={this.state.data.map(data => ({key: data.name, text:data.name, value: data.name}))} onChange={this.onSelect} value={this.state.selected} fluid selection placeholder="Выбирите Слой" />
                <div style={{display:this.state.editdisplay}}>
                {this.state.typedata[1].data.map(({name,type,table,wordtable}) => {
                    // console.log(name,type,type === 'String')
                    if (type === 'String'){
                        //  console.log("STRING")
                        return (
                            <Input
                                icon='tags'
                                iconPosition='right'
                                label={{ tag: true, content: name }}
                                labelPosition='right'
                                placeholder='Enter value'
                                onChange={this.input2}
                            />
                        )
                    }
                })}

            </div>
                <div id={"5"} style={{display:this.state.fielddisplay}}>
                    {this.state.typedata[1].data.map(({name,type,table,wordtable}) => {
                        // console.log(name,type,type === 'String')
                        if (type === 'String'){
                            //  console.log("STRING")
                            return (
                                <Input
                                    icon='tags'
                                    iconPosition='right'
                                    label={{ tag: true, content: name }}
                                    labelPosition='right'
                                    placeholder='Enter value'
                                    onChange={this.input2}
                                />
                            )
                        }
                    })}

                </div>
                    <div id={"6"} style={{display:this.state.wellsdisplay}}>
                    {this.state.typedata[1].data.map(({name,type,table,wordtable}) => {
                        // console.log(name,type,type === 'String')
                        if (type === 'String'){
                            //  console.log("STRING")
                            return (
                                <Input
                                    icon='tags'
                                    iconPosition='right'
                                    label={{ tag: true, content: name }}
                                    labelPosition='right'
                                    placeholder='Enter value'
                                    onChange={this.input2}
                                />
                            )
                        }
                    })}
                </div>
                    <div id={"7"} style={{display:this.state.seismicssdisplay}}>
                    {this.state.typedata[1].data.map(({name,type,table,wordtable}) => {
                        // console.log(name,type,type === 'String')
                        if (type === 'String'){
                            //  console.log("STRING")
                            return (
                                <Input
                                    icon='tags'
                                    iconPosition='right'
                                    label={{ tag: true, content: name }}
                                    labelPosition='right'
                                    placeholder='Enter value'
                                    onChange={this.input2}
                                />
                            )
                        }
                    })}
                </div>
                <div id={"8"} style={{display:this.state.geoobjectsdisplay}}>
                    {this.state.typedata[1].data.map(({name,type,table,wordtable}) => {
                        // console.log(name,type,type === 'String')
                        if (type === 'String'){
                            //  console.log("STRING")
                            return (
                                <Input
                                    icon='tags'
                                    iconPosition='right'
                                    label={{ tag: true, content: name }}
                                    labelPosition='right'
                                    placeholder='Enter value'
                                    onChange={this.input2}
                                />
                            )
                        }
                    })}

                </div>
                <div id={"9"} style={{display:this.state.subbasinsdisplay}}>
                    {this.state.typedata[1].data.map(({name,type,table,wordtable}) => {
                        // console.log(name,type,type === 'String')
                        if (type === 'String'){
                            //  console.log("STRING")
                            return (
                                <Input
                                    icon='tags'
                                    iconPosition='right'
                                    label={{ tag: true, content: name }}
                                    labelPosition='right'
                                    placeholder='Enter value'
                                    onChange={this.input2}
                                />
                            )
                        }
                    })}

                </div>
                <div id={"10"} style={{display:this.state.licensesdisplay}}>
                    {this.state.typedata[1].data.map(({name,type,table,wordtable}) => {
                        // console.log(name,type,type === 'String')
                        if (type === 'String'){
                            //  console.log("STRING")
                            return (
                                <Input
                                    icon='tags'
                                    iconPosition='right'
                                    label={{ tag: true, content: name }}
                                    labelPosition='right'
                                    placeholder='Enter value'
                                    onChange={this.input2}
                                />
                            )
                        }
                    })}

                </div>
                <div id={"11"} style={{display:this.state.geologicmapdisplay}}>
                    {this.state.typedata[1].data.map(({name,type,table,wordtable}) => {
                        // console.log(name,type,type === 'String')
                        if (type === 'String'){
                            //  console.log("STRING")
                            return (
                                <Input
                                    icon='tags'
                                    iconPosition='right'
                                    label={{ tag: true, content: name }}
                                    labelPosition='right'
                                    placeholder='Enter value'
                                    onChange={this.input2}
                                />
                            )
                        }
                    })}

                </div>
                <div id={"12"} style={{display:this.state.tectonicmapdisplay}}>
                    {this.state.typedata[1].data.map(({name,type,table,wordtable}) => {
                        // console.log(name,type,type === 'String')
                        if (type === 'String'){
                            //  console.log("STRING")
                            return (
                                <Input
                                    icon='tags'
                                    iconPosition='right'
                                    label={{ tag: true, content: name }}
                                    labelPosition='right'
                                    placeholder='Enter value'
                                    onChange={this.input2}
                                />
                            )
                        }
                    })}

                </div>
                <div id={"13"} style={{display:this.state.regionsdisplay}}>
                    {this.state.typedata[1].data.map(({name,type,table,wordtable}) => {
                        // console.log(name,type,type === 'String')
                        if (type === 'String'){
                            //  console.log("STRING")
                            return (
                                <Input
                                    icon='tags'
                                    iconPosition='right'
                                    label={{ tag: true, content: name }}
                                    labelPosition='right'
                                    placeholder='Enter value'
                                    onChange={this.input2}
                                />
                            )
                        }
                    })}

                </div>
                    </span>)}
                {(this.state.selected !== null)&&(
                    <GroupPermissionForObject csstop={null} arrayChange={(array)=>{this.setState({groupPermArray:array})}} permArrayBasic={this.state.groupPermArrayBasic} permArray={this.state.groupPermArray} id={null} close={()=>{this.setState({PermissionDisplay:"none"})}} display={this.state.PermissionDisplay} />
                )}
                <Button onClick={this.handleClick.bind(this)}>Save</Button>
                <Button onClick={this.handleCancel}>Cancel</Button>
            </div>
        )
    }
}
export default UpdateGeometry;